import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Mirador from '../lib/mirador/mirador.min.js';
import { getTranscriptions } from "../services/transcriptionService";
import { getFormattedCanvasId, getFormattedManifestId } from "../lib/utils";
import { CONSTANTS } from "../lib/constants";
import ChatBotPlugin from './chatbotPlugin.js'

const actions = Mirador.actions;

const transcriptionReducer = (state = {}, action) => {
    if (action.type === 'mirador/TOGGLE_WINDOW_SIDE_BAR') {
        // return the updated data with the original state variables
        return {
            ...state,
            [action.windowId]: state[action.windowId] || { transcription: false, chatbot: false },
        };
    }
    if (action.type === 'mirador/REMOVE_WINDOW') {
        // make a copy of the data to update
        var updatedData = {
            ...state
        };

        delete updatedData[action.windowId];

        // return the updated data with the original state variables
        return {
            ...updatedData
        };
    }
    if (action.type === 'mirador/UPDATE_COMPANION_WINDOW') {
        // make a copy of the data to update
        var updatedData = {
            ...action.payload
        };

        updatedData['transcription'] = action.payload.transcription ? true : false;
        updatedData['chatbot'] = action.payload.chatbot ? true : false;

        // return the updated data with the original state variables
        return {
            ...state,
            [action.windowId]: updatedData,
        };
    }
    if (action.type === 'mirador/MINIMIZE_WINDOW' || action.type === 'mirador/MAXIMIZE_WINDOW'){
        let type = "min";
        let setOtherWindowScale = true;
        if(action.type === 'mirador/MAXIMIZE_WINDOW'){
            type = "max";
            setOtherWindowScale = false;
        }
        if(state && state[action.windowId]){
            state[action.windowId]['windowScale'] = type;
            state[action.windowId]['setOtherWindowScale'] = setOtherWindowScale;
        }
        // return the updated data with the original state variables
        return {
            ...state
        };
    }
    if (action.type === 'mirador/UPDATE_TRANSCRIPTION'){
        if(state[action.windowId]){
            const payload = action.payload
            if(payload.windowScale != undefined){
                state[action.windowId]['windowScale'] = payload.windowScale;
            }
            if(payload.setOtherWindowScale != undefined){
                state[action.windowId]['setOtherWindowScale'] = payload.setOtherWindowScale;
            }
            return {
                ...state
            };
        }
    }
    return state;
};

const mapDispatchToProps = (dispatch) => ({
    openSearchPanel: (windowId, payload) => dispatch(actions.addOrUpdateCompanionWindow(windowId, payload)),
    updateTranscriptionData: (windowId, payload) => dispatch({type: 'mirador/UPDATE_TRANSCRIPTION', windowId: windowId, payload: payload })
});

const mapStateToProps = (state) => {
    return {
        state: state
    };
};

class TranscriptionPlugin extends Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.windowId = props.targetProps.windowId;
        this.handleSelection = this.handleSelection.bind(this);
    }

    /**
     * 
     * @param {object} prevProps 
     * @description life cycle method to compare previous and current state
     */
    componentDidUpdate(prevProps) {
        this.detectTabChange(prevProps);
        this.detectCanvasChange(prevProps);
        this.detectSideBarToggle(prevProps);
        this.detectViewTypeChange(prevProps);
        this.detectWindowMinimiseMaximise();
    }

    /**
     * @param {object} prevProps 
     * @description to detect last tab selected and side panel opened
     */
    detectSideBarToggle(prevProps) {
        const { state, targetProps } = this.props;
        if (state.windows[targetProps.windowId] && state.windows[targetProps.windowId].sideBarOpen !== prevProps.state.windows[targetProps.windowId].sideBarOpen) {

            if (state.transcription && state.transcription[targetProps.windowId].transcription) {
                this.deselectTabs();
                setTimeout(() => {
                    this.selectTab();
                }, 200);
            }
        }
    }

    /**
     * 
     * @param {object} prevProps 
     * @description to detect canvas change
     */
    detectCanvasChange(prevProps) {
        if (document.getElementsByClassName('mirador-canvas-count')[0]) {
            const pageIndex = document.getElementsByClassName('mirador-canvas-count')[0].innerText.split(' ')[0];
            parent.postMessage({pageIndex:pageIndex},ENV.USER_BASE_URL);
        }
        const { state, targetProps } = this.props;
        if (state.windows[targetProps.windowId] && state.windows[targetProps.windowId].canvasId !== prevProps.state.windows[targetProps.windowId].canvasId) {            
            if (state.transcription && state.transcription[targetProps.windowId] && state.transcription[targetProps.windowId].transcription) {
                this.selectTab();
            }
            else {
                this.getTranscriptionData()
            }
        }
    }

    /**
     * 
     * @param {object} prevProps 
     * @description to detect sidebar tab change
     */
    detectTabChange(prevProps) {
        const { targetProps, state } = this.props;
        // check if transcription object is empty
        if (!state.transcription || !state.transcription[targetProps.windowId] || !prevProps.state.transcription[targetProps.windowId]) {
            return;
        }

        // check if transcription tab is click
        if (state.transcription[targetProps.windowId].transcription !== prevProps.state.transcription[targetProps.windowId].transcription) {
            if (state.transcription[targetProps.windowId].transcription) {
                this.selectTab();
            } else {
                this.deselectTabs();
                this.manipulateState();
            }
        }
    }

    /**
     * @description manipulate state when tab is changed from transcription to search
     */
    manipulateState() {
        const { targetProps, state } = this.props;
        if (state.transcription && state.transcription[targetProps.windowId].content === 'search') {
            this.props.openSearchPanel(targetProps.windowId, {
                content: 'annotations',
                position: 'left'
            })
            setTimeout(() => {
                this.props.openSearchPanel(targetProps.windowId, {
                    content: 'search',
                    position: 'left'
                })
            }, 10);
        }
    }

    /**
     * @description handle button selection
     */
    handleSelection() {
        const { targetProps } = this.props;

        this.props.openSearchPanel(targetProps.windowId, {
            content: 'search',
            position: 'left',
            transcription: true
        });
    }

    /**
     * @description Set the ref to the parent tabs element
     */
    setRef(ref) {
        const { targetProps } = this.props;
        if (targetProps.sideBarOpen) {
            if (this.sideBarRef) return;

            this.sideBarRef = ref;
        } else {
            this.sideBarRef = null;
        }
    }

    /**
     * @description select transcription tab
     */
    selectTab() {
        setTimeout(() => {
            if (this.sideBarRef === null) {
                return;
            }
            const refs = ReactDOM.findDOMNode(this.sideBarRef);
            const tab = refs.querySelector('.transcription-btn');

            this.deselectTabs();
            this.activateTab(tab);
            this.getTranscriptionData();
        }, 100);
    }

    /**
     * selects the given tab
     * @param {HTMLElement} tab the tab to activate
     */
    activateTab(tab) {
        tab.removeAttribute('tabIndex');
        tab.setAttribute('aria-selected', 'true');
        tab.focus();
    }

    /**
     * @description deselect all tabs when selection changed
     */
    deselectTabs() {
        if (this.sideBarRef === null) {
            return;
        }
        const refs = ReactDOM.findDOMNode(this.sideBarRef);
        this.tabs = Array.from(refs.querySelectorAll('button[role=tab]'));

        this.tabs.forEach(tab => {
            tab.setAttribute('tabindex', '-1');
            tab.setAttribute('aria-selected', 'false');
            tab.classList.add('tab-unselected');
            tab.classList.remove('Mui-selected');
        });
    }

    /**
     * @description get transcription data from server
     */
    async getTranscriptionData() {
        const { state, targetProps } = this.props;
        let canvasId = '';
        let allowNextId = false;
        let isReverse = false;
        let nextId = '';
        const manifestId = state.windows[targetProps.windowId].manifestId;
        if (!state.windows[targetProps.windowId].canvasId) {
            canvasId = state.manifests[manifestId].json.items[0].id;
        } else {
            canvasId = state.windows[targetProps.windowId].canvasId;
        }
        if(state.windows[targetProps.windowId].view === 'book'){
            const getNextCanvasId = await this.getNextCanvasId(state.manifests[manifestId].json.items,canvasId);
            if(getNextCanvasId.status){
                allowNextId = true;
                nextId = getNextCanvasId.nextId;
                isReverse = getNextCanvasId.isReverse;
            }
        }
        const requestObject = {
            manifestId: getFormattedManifestId(manifestId),
            canvasId: getFormattedCanvasId(canvasId)
        }

        getTranscriptions(requestObject).then((response) => {
            let canvas = state.manifests[manifestId].json.items.find((item) => item.id === canvasId);
            let canvasPageLabel = `<hr><b>Page: ${canvas.label ? canvas.label : state.manifests[manifestId].json.items.findIndex((item) => item.id === canvasId)+1 } </b><hr></hr>`;

            if (allowNextId) {
                const requestNextObject = {
                    manifestId: getFormattedManifestId(manifestId),
                    canvasId: getFormattedCanvasId(nextId)
                }
                getTranscriptions(requestNextObject).then((nextResponse) => {
                    let canvasMessageContent = response.data.transcription;
                    let nextCanvasMessageContent = nextResponse.data.transcription;
                    if (isReverse) {
                        canvasMessageContent = nextResponse.data.transcription;
                        nextCanvasMessageContent = response.data.transcription;
                    }
                    let nextCanvas = state.manifests[manifestId].json.items.find((item) => item.id === nextId);
                    let nextCanvasPageLabel = `<hr><b>Page: ${nextCanvas.label ? nextCanvas.label : state.manifests[manifestId].json.items.findIndex((item) => item.id === nextId)+1} </b><hr></hr>`;
                    
                    let replaceHtmlData = canvasMessageContent;
                    if (!replaceHtmlData) {
                        replaceHtmlData = nextCanvasMessageContent ? `${nextCanvasPageLabel}${nextCanvasMessageContent}` : nextCanvasMessageContent;
                    } else {
                        replaceHtmlData = nextCanvasMessageContent ? `${canvasPageLabel}${replaceHtmlData}${nextCanvasPageLabel}${nextCanvasMessageContent}` : `${canvasPageLabel}${replaceHtmlData}` ;
                    }
                    this.replaceHTML(replaceHtmlData || CONSTANTS.NO_TRANSCRIPTION_EXISTS);
                }).catch((error) => {
                    console.log(error);
                })
            } else {
                if (!this.sideBarRef ||this.sideBarRef === null) {
                    return;
                }
                const refs = ReactDOM.findDOMNode(this.sideBarRef);
                let transcriptionTab = false
                const selectedTab = refs.querySelector('[aria-selected="true"]');
                transcriptionTab = selectedTab ? selectedTab.classList.contains('transcription-btn') : false
                if (transcriptionTab) {
                this.replaceHTML(response.data.transcription ? `${canvasPageLabel}${response.data.transcription}` : CONSTANTS.NO_TRANSCRIPTION_EXISTS);
            }
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    getNextCanvasId(items,canvasId){
        return new Promise((resolve,reject) => {
            let canvasIndex = 0;
            let cnt = 0;
            if(items[0].id === canvasId){
                return resolve({status : false});
            }
            for (let index = 0; index < items.length; index++) {
                const element = items[cnt];
                if(element.id === canvasId){
                   canvasIndex = cnt;
                   if(canvasIndex > 0) {
                        let nextId = items[canvasIndex + 1] ? items[canvasIndex + 1].id : '';
                        let isReverse = false;
                        if(canvasIndex % 2 === 0){
                            nextId = items[canvasIndex - 1] ? items[canvasIndex - 1].id : '';
                            isReverse = true;
                        }
                        if(nextId !== undefined && nextId !== ''){
                            return resolve({status : true, nextId : nextId, isReverse : isReverse });
                        }
                    }
                }
                cnt = cnt + 1;
                if(cnt === items.length){
                    return resolve({status : false});
                } 
            }
        })
    }

    /**
     * 
     * @param {string} transcription 
     * @description replace companion window HTML with transcription
     */
    replaceHTML(transcription) {
        try {
            if (!this.sideBarRef || this.sideBarRef === null) {
                return;
            }
            const transcriptionCompanionArea = this.sideBarRef.offsetParent.getElementsByClassName('mirador-companion-area-left')[0];
            if (transcriptionCompanionArea) {
                const header = transcriptionCompanionArea.querySelector('.MuiTypography-h3');
                header.classList.add('mt-3');
                header.classList.add('mb-2');
                header.innerHTML = 'Transcription';

                header.nextElementSibling.classList.add('d-none');

                const headerControl = transcriptionCompanionArea.querySelector('.mirador-companion-window-title-controls');
                headerControl.classList.add('d-none');

                const body = transcriptionCompanionArea.querySelector('.MuiPaper-elevation0');
                body.innerHTML = `<div class="m-3">${transcription}</div>`;
            }
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        const { targetProps,state } = this.props;
        const manifestId = state.windows[targetProps.windowId].manifestId;
        const aiChatBot =
          state.manifests[manifestId] && state.manifests[manifestId].json
            ? state.manifests[manifestId].json.aiChatBot
            : '';
        return (
            <React.Fragment>
                {(() => {
                    if (targetProps.sideBarOpen) {
                        return (
                            <div className="sidebar-container" id={`${targetProps.windowId}-sidebar-container`} ref={ref => this.setRef(ref)} >
                                <div class="button-container">
                                    <button className="MuiTab-textColorPrimary transcription-btn" aria-selected="false" aria-label="Transcription" tabIndex="0" role="tab"
                                        onClick={this.handleSelection}>
                                        <svg className="MuiSvgIcon-root" version="1.0" viewBox="0 0 640 638" xmlns="http://www.w3.org/2000/svg">
                                            <g transform="translate(0 638) scale(.1 -.1)">
                                                <path d="m295 6362c-120-41-205-117-258-230l-32-67v-2750-2750l28-59c15-33 44-80 64-104 40-48 134-114 192-134 28-10 364-14 1455-18l1418-5 57-99c64-110 92-136 155-143 82-10 126 30 178 162l33 85h1108 1112c3 1 28 8 57 16 125 37 215 114 271 232l32 67 3 1734 2 1733 88 93c127 134 165 224 131 312-23 61-99 116-178 129l-41 6-2 747-3 746-24 59c-17 42-42 78-90 125-67 68-125 103-203 121-28 6-1007 9-2773 9-2564 0-2733-1-2780-17zm5496-352c19-10 19-29 19-778l-1-767-76-44c-92-54-264-178-398-289l-99-82h-2196c-2115 0-2196-1-2236-19-135-61-135-245 0-319 29-16 168-17 2033-17 1102 0 2003 0 2003-1 0 0-88-88-196-195-107-107-281-285-387-396l-191-203-1615-2c-1484-3-1618-4-1646-20-101-56-131-167-70-259 53-81-87-73 1544-79l1453-5-128-145c-71-80-178-202-237-272l-109-127-219 110c-368 184-633 289-833 330-227 47-386-1-451-137-21-43-31-129-21-170 4-16 78-96 189-205l182-178-633-1c-698 0-673 2-727-62-70-83-50-201 44-265l34-23h793 792l40-47c88-107 296-407 440-635l61-98h-1276c-1174 0-1278 1-1295 17s-18 112-18 2689c0 2416 2 2674 16 2688s273 16 2705 16c1835 0 2696-3 2710-10zm-181-2117c-503-507-832-907-1207-1469-352-529-685-1139-948-1739-49-110-89-201-89-202-1-1-57 90-124 202-343 569-625 945-980 1307l-164 167 63-15c185-41 440-148 839-350 155-78 277-134 294-134 53 0 93 30 187 143 700 838 1592 1752 2120 2171 65 51 296 216 303 216 1 0-132-134-294-297zm195-3250c-3-13-15-25-28-28-12-3-475-4-1030-3l-1007 3 108 231c268 570 616 1172 952 1647l34 47h245c218 0 251 3 287 19 99 45 133 167 71 260-38 57-83 74-216 81l-113 5 129 155c164 196 295 342 448 498l120 124 3-1509c1-829 0-1518-3-1530z" />
                                                <path d="m789 5177c-120-81-114-243 12-307 38-20 64-20 2298-18l2260 3 28 21c61 45 92 119 79 189-8 40-55 99-97 121-24 12-334 14-2287 14h-2259l-34-23z" />
                                                <path d="m5755 4078c-522-378-1477-1338-2259-2272-110-131-129-147-189-153-38-4-66 8-327 139-371 188-674 315-819 342l-34 6 27-21c42-33 303-314 407-439 222-265 448-588 681-975l122-202 57 131c567 1310 1327 2437 2242 3329 94 92 169 167 167 166-3 0-36-23-75-51z" />
                                            </g>
                                        </svg>
                                    </button>
                                 {aiChatBot==='enable'?   <ChatBotPlugin {...this.props} sideBarRef={this.sideBarRef} ></ChatBotPlugin>:null}
                                </div>
                                <this.props.TargetComponent {...targetProps} windowId={targetProps.windowId}/>
                            </div>
                        );
                    } else {
                        return null;
                    }
                })()}
            </React.Fragment>
        );
    }

    detectViewTypeChange(prevProps) {
        const { targetProps, state } = this.props;
        const { windows, transcription } = state;
        if(!targetProps.windowId) {
            return false;
        }
        const windowId = targetProps.windowId;
        if( windows[windowId] && prevProps.state.windows[windowId] ) {
            const windowData = windows[windowId];
            const currentView = windowData.view;
            const previousWindowData = prevProps.state.windows[windowId];
            const previousView = previousWindowData.view;
            let transcriptionData = false;
            if(transcription && transcription[windowId]) {
                transcriptionData = transcription[windowId].transcription;
            }
            if(currentView && previousView && transcriptionData && currentView != previousView) {
                this.getTranscriptionData();
            }
        }
    }

    detectWindowMinimiseMaximise(){
        const { targetProps, state } = this.props;
        const { transcription } = state;
        if(!targetProps.windowId) {
            return false;
        }
        const windowId = targetProps.windowId;
        const windowData = transcription && transcription[windowId];
        let transcriptionData = false;
        if(transcription && transcription[windowId]) {
            transcriptionData = transcription[windowId].transcription;
        }
        if(windowData && transcriptionData) {
            if( windowData.windowScale === 'max' || windowData.windowScale === 'min'){
                this.props.updateTranscriptionData(windowId, { "windowScale" : "none" })
                this.selectTab();
                return false;
            }
            if(windowData.setOtherWindowScale){
                this.props.updateTranscriptionData(windowId, { "setOtherWindowScale" : false })
                const transcriptionKeys = Object.keys(this.props.state.transcription);
                transcriptionKeys.forEach(element => {
                    if(element !== windowId){
                        const otherWindow = this.props.state.transcription[element];
                        if(otherWindow.transcription){
                            this.props.updateTranscriptionData(element, { "windowScale" : "min" });
                        }
                    }
                });  
            }
        }
    }
}

export default {
    target: 'WindowSideBar',
    mode: 'wrap',
    name: 'TranscriptionPlugin',
    component: TranscriptionPlugin,
    mapStateToProps,
    mapDispatchToProps,
    reducers: {
        transcription: transcriptionReducer,
    },
};
