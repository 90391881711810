const { CONSTANTS } = require('./constants');

// To get all 12 months in single array
const getAllMonthNames = () => [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

// to get year value from date
const getYearFromDate = (date) => {
  if (date.toLowerCase() === 'undated') {
    return '0000';
  }
  if (date && date.split('-')[0]) {
    return date.split('-')[0];
  }
  return null;
};

// to get month value from date
const getMonthFromDate = (date) => {
  if (date.toLowerCase() === 'undated') {
    return '00';
  }
  if (date && date.split('-')[1]) {
    return date.split('-')[1];
  }
  return null;
};

// to get day value from date
const getDayFromDate = (date) => {
  if (date.toLowerCase() === 'undated') {
    return '00';
  }
  if (date && date.split('-')[2]) {
    return date.split('-')[2];
  }
  return null;
};

// to get default 0000 as year
const getDefaultYear = () => '0000';

// to get default 00 as month or day
const getDefaultMonthOrDay = () => '00';

// to get Undated as default date
const getDefaultDate = () => 'Undated';

// to get month name from given month number
const getMonthNameFromMonth = month =>
  (getAllMonthNames()[month - 1]
    ? getAllMonthNames()[month - 1]
    : '');

// to get formatted month with day for e.g January 15th
const getFormattedMonthWithDay = (date) => {
  let formattedMonthAndDay = '';
  if (
    isMonthExistInDate(date) &&
    isValidMonth(getMonthFromDate(date))
  ) {
    formattedMonthAndDay = `${getMonthNameFromMonth(parseInt(getMonthFromDate(date), 10))}`;
  }
  if (
    isDayExistInDate(date) &&
    isValidDay(getDayFromDate(date))
  ) {
    formattedMonthAndDay = formattedMonthAndDay.concat(` ${getDayFromDate(date)}`);
  }
  // if formatted date is not empty then add comma (,) at the end of date
  return formattedMonthAndDay !== ''
    ? formattedMonthAndDay.concat(', ')
    : formattedMonthAndDay;
};

/*
 * To get formatted year at the end of formatted date
 * if year exist in date and it is the valid year
 * else remove previously added comma if year not exist/valid in date.
 * In this case date will be like January 15th
 */
const getFormattedYear = (date, formattedDate) =>
  (isYearExistInDate(date) && isValidYear(getYearFromDate(date))
    ? formattedDate.concat(`${getYearFromDate(date)}`)
    : removeCommaFromFormattedDate(formattedDate));

// to check year is valid or not
const isValidYear = year =>
  (!!(parseInt(year, 10) >= 1500 &&
    parseInt(year, 10) <= new Date().getFullYear() &&
    new RegExp(CONSTANTS.DATE_REGEX.YEAR)));

// to check year exist in date or not
const isYearExistInDate = date =>
  (getYearFromDate(date) !== getDefaultYear());

// to check month is valid or not
const isValidMonth = month =>
  (!!(parseInt(month, 10) > 0 &&
    parseInt(month, 10) <= 12 &&
    new RegExp(CONSTANTS.DATE_REGEX.MONTH_AND_DAY).test(month)));

// to check month exist in date or not
const isMonthExistInDate = date =>
  (getMonthFromDate(date) !== getDefaultMonthOrDay());

// to check day is valid or not
const isValidDay = day =>
  (!!(parseInt(day, 10) > 0 &&
    parseInt(day, 10) <= 31 &&
    new RegExp(CONSTANTS.DATE_REGEX.MONTH_AND_DAY).test(day)));

// to check day exist in date or not
const isDayExistInDate = date =>
  (getDayFromDate(date) !== getDefaultMonthOrDay());

// to check date is valid or not
const isValidDate = date =>
  (!!new RegExp(CONSTANTS.DATE_REGEX.FULL_DATE).test(date));

// to check the date is default
const isDefaultDate = date => (date === '0000-00-00');

/*
 * removes comma from formatted date if comma exist at the end of formatted date
 * In the date in which year not exist for e.g. January 15th, OR January,
 */
const removeCommaFromFormattedDate = date => date.trim().slice(0, -1);

/*
 * To get formatted date from given date string
 * For e.g. if date value is 2021-01-02, it will return 02nd January 2021
 */
exports.getFormattedDate = (date) => {
  if (isValidDate(date)) {
    // get formatted Month with day for e.g. January 15th
    let formattedDate = getFormattedMonthWithDay(date);
    // add year to formatted date
    formattedDate = getFormattedYear(date, formattedDate);
    /*
     * if the given date is default
     * WE ARE STORING Undated instead of 0000-00-00 in case if the date is 0000-00-00,
     * then return Undated as date
     */
    return isDefaultDate(date) ? getDefaultDate() : formattedDate;
  }
  // if date is not valid then return Undated as date
  return getDefaultDate();
};

exports.getFormattedDateForTooltip = (date) => {
  const partsOfDate = date.replaceAll('*','').split('-');
  let formattedDate = '';
  if(isValidMonth(partsOfDate[1])) {
    formattedDate = getMonthNameFromMonth(parseInt(partsOfDate[1], 10))
  } else if(partsOfDate[1] === '00') {
    formattedDate = 'Unknown'
  }
  if(isValidDay(partsOfDate[2])) {
    formattedDate = formattedDate.concat(` ${partsOfDate[2]}`);
  } else if (partsOfDate[2] === '00') {
    formattedDate = formattedDate.concat(` Unknown`);
  }
  formattedDate = formattedDate !== '' ? formattedDate.concat(', ') : formattedDate;
  if(isValidYear(partsOfDate[0])){
    formattedDate = formattedDate.concat(`${partsOfDate[0]}`);
  } else if (partsOfDate[0] === '0000') {
    formattedDate = formattedDate.concat(`Unknown`);
  }
  formattedDate = formattedDate === '' && date.toLowerCase() === 'undated' ? date : formattedDate;
  return formattedDate;
}


exports.getFullDateRegex = () => /^\d{4}-\d{2}-\d{2}$/

exports.getDateWithoutDay = () => /^\d{4}-\d{2}$/

exports.getDateWithoutYear = () => /^\d{2}-\d{2}$/