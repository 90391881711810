import React, { Component } from "react";
import PropTypes from 'prop-types';
import './Button.scss';

class Button extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { title, containerId, clickHandler, className, children, ...otherProps } = this.props;
    const buttonClasses = className ? className : 'btn btn-primary';
    return (
      <button
        title={title}
        className={buttonClasses}
        containerId={containerId}
        onClick={clickHandler}
        {...otherProps}
      >
        {children}
      </button>
    );
  }
}

Button.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  containerId: PropTypes.string,
  clickHandler: PropTypes.func,
}

export default Button