import React from 'react';

// Common components
import Button from '../../components/Button';
import Slider from '../../components/Slider';

/*
 * @description render view for image tools (brightness, contrast, saturation, greyscale, invert colors)
*/
const ImageToolComponent = (props) => {
  const {
    labelId, label,containerId, handleClick, open, toggled, handleChange, children, max, min, value
  } = props;
  return (
    <div style={{ display: 'inline-block', position: 'relative' }}>
      <Button
        id={`${labelId}-label`}
        title={label}
        containerId={containerId}
        clickHandler={handleClick}
        aria-expanded={open}
        aria-controls={labelId}
        className="MuiButtonBase-root MuiIconButton-root"
        style={{ backgroundColor: (open || toggled) ? 'rgba(0, 0, 0, 0.25)' : '' }}
      >
        {children}
      </Button>
      {open && (
        <div className="image-tools-range-slider">
          <Slider
            min={min}
            max={max}
            value={value}
            changeHandler={handleChange}
          />
        </div>
      )}
    </div>
  );
}

export default ImageToolComponent;