import React, { Component } from "react";
import Collapse, { Panel } from 'rc-collapse';
import uuidV4 from "uuid/v4";
import closeIcon from "../../images/close-icon.png";

export default class CustomCollapse extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { type, label, panelData, handleFilterSelect, removeFilter, comparator, showMoreFilters } = this.props;
        return (
            <Collapse accordion={true} id={type} key={`collapse-${uuidV4()}`}>
                <Panel header={label}>
                    {panelData.map((data) => {
                        return (
                            <p key={`${uuidV4()}`}>
                                <span onClick={() => { handleFilterSelect(data.key, type) }}>{data.key}</span>
                                {comparator[type] !== data.key && (<span>{data.doc_count}</span>)}
                                {comparator[type] === data.key && (
                                    <span onClick={() => { removeFilter(type) }}>
                                        <img src={closeIcon} alt="" />
                                    </span>)}
                            </p>
                        );
                    })}
                    {panelData.length > 4 && (<p className="more" onClick={() => { showMoreFilters(type) }}>More...</p>)}
                </Panel>
            </Collapse>
        );
    }
}
