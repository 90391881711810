import axios from "axios";


const getAuthorizationToken = () => {
    return localStorage.getItem('nat') ? localStorage.getItem('nat') : '';
}

const getHeadersForOpenAPIs = async () => {
  return {
    'Authorization': getAuthorizationToken(),
    'isOpenAPI': 'true',
    'oauthAccessToken': await getOauthAccessToken()
  }
}

const setOAuthAccessToken = async () => {
  const response = await axios.get(`${ENV.API_URL}/oauth/token`,{
    headers: {
      'skipAuth': 'true',
      'Authorization':
        'Basic ' +
        btoa(`${ENV.OAUTH_CONSUMER_KEY}:${ENV.OAUTH_CONSUMER_SECRET}`),
    }
  });
  if(response.data && response.data.accessToken){
    localStorage.setItem('oat', response.data.accessToken);
  }
}

const getOauthAccessToken = async () => {
  if (!localStorage.getItem('oat')) {
    await setOAuthAccessToken();
  }
  return localStorage.getItem('oat')  ? localStorage.getItem('oat') : '';
};

export const getDocuments = async (params) => {
    try {
        return await axios.post(`${ENV.API_URL}/digital_document/search/index`, params, {
            headers: await getHeadersForOpenAPIs()
        });
    } catch (error) {
        console.log(error);
    }
}

export const getFilters = async (params) => {
    try {
        return await axios.post(`${ENV.API_URL}/digital_document/search/filter/all`, params, {
          headers: await getHeadersForOpenAPIs()
        });
    } catch (error) {
        console.log(error);
    }
}

export const getFiltersByType = async (params, type) => {
    try {
        return await axios.post(`${ENV.API_URL}/digital_document/search/filter/${type}`, params, {
          headers: await getHeadersForOpenAPIs()
        });
    } catch (error) {
        console.log(error);
    }
}

export const getManifestData = async (manifestId) => {
    try {
        return await axios.get(manifestId);
    } catch (error) {
        console.log(error);
    }
}