import React, { Component } from 'react';
import PropTypes from 'prop-types';

const searchDialogReducer = (state = {}, action) => {
    if (action.type === 'mirador/OPEN_WINDOW_DIALOG') {
        return {
            ...state,
            openDialog: action.dialogType,
        };
    }

    if (action.type === 'mirador/CLOSE_WINDOW_DIALOG') {
        return {
            ...state,
            openDialog: null,
        };
    }
    return state;
};

const mapDispatchToProps = (dispatch) => ({
    openSearchDialog: () => dispatch({ type: 'mirador/OPEN_WINDOW_DIALOG', dialogType: 'search' }),
});

const mapStateToProps = (state) => {
    return {
        state: state
    }
};

class DocumentSearchPlugin extends Component {
    constructor(props) {
        super(props);
    }

    openDialog() {
        const { openSearchDialog } = this.props;

        openSearchDialog();
    }

    /**
     * @description render view
     */
    render() {
        const { targetProps } = this.props;
        const thisWindow = this.props.state.windows[Object.keys(this.props.state.windows)[0]]
        const downloadPDF = thisWindow && thisWindow.downloadPDF ? thisWindow.downloadPDF : undefined ;

        return (
          <React.Fragment>
            <button
              className="MuiButtonBase-root MuiFab-root MuiFab-primary MuiFab-sizeMedium WithWidth(undefined)-fab-597 mt-2 ml-1"
              tabIndex="0"
              type="button"
              id="customAddBtn"
              aria-label="Add resource"
              title="Add resource"
              onClick={() => this.openDialog()}
            >
              <span className="MuiFab-label">
                <svg
                  className="MuiSvgIcon-root"
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  role="presentation"
                >
                  <path fill="none" d="M0 0h24v24H0V0z"></path>
                  <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path>
                </svg>
              </span>
            </button>
            {downloadPDF &&  downloadPDF.pdfURL && downloadPDF.isDownloadEnable ? (
            <a
              hidden={!downloadPDF.pdfURL}
              href={downloadPDF.pdfURL}
              aria-label="Add resource"
              title="Click to Download PDF"
            >
              <button
                className="MuiButtonBase-root MuiFab-root MuiFab-primary MuiFab-sizeMedium WithWidth(undefined)-fab-597 mt-2 ml-1"
                tabIndex="1"
                type="button"
                id="customAddBtn"
                aria-label="Click to Download PDF"
                title="Click to Download PDF"
              >
                <span className="MuiFab-label" style={{ width: '50%' }}>
                  <svg
                    data-name="Livello 1"
                    id="Livello_1"
                    viewBox="0 0 128 128"
                    xmlns="http://www.w3.org/2000/svg"
                    className="MuiSvgIcon-root"
                  focusable="false"
                  aria-hidden="true"
                  role="presentation"
                  >
                    <title></title>
                    <path
                      d="M61.88,93.12h0a3,3,0,0,0,.44.36l.24.13a1.74,1.74,0,0,0,.59.24l.25.07h0a3,3,0,0,0,1.16,0l.26-.08.3-.09a3,3,0,0,0,.3-.16l.21-.12a3,3,0,0,0,.46-.38L93,66.21A3,3,0,1,0,88.79,62L67,83.76V3a3,3,0,0,0-6,0V83.76L39.21,62A3,3,0,0,0,35,66.21Z"
                      stroke="#ffffff"
                      stroke-width="6"
                    />
                    <path
                      d="M125,88a3,3,0,0,0-3,3v22a9,9,0,0,1-9,9H15a9,9,0,0,1-9-9V91a3,3,0,0,0-6,0v22a15,15,0,0,0,15,15h98a15,15,0,0,0,15-15V91A3,3,0,0,0,125,88Z"
                      stroke="#ffffff"
                      stroke-width="6"
                    />
                  </svg>
                </span>
              </button>
            </a>
            ) : null}

            <this.props.TargetComponent {...targetProps} />
          </React.Fragment>
        );
    }
}

DocumentSearchPlugin.propTypes = {
    openSearchDialog: PropTypes.func.isRequired
};

export default {
    target: 'WorkspaceMenuButton',
    mode: 'wrap',
    name: 'DocumentSearchPlugin',
    component: DocumentSearchPlugin,
    mapStateToProps,
    mapDispatchToProps,
    reducers: {
        windowDialogs: searchDialogReducer,
    },
};
