import axios from "axios";

export const getUserProfile = async () => {
    const authToken = localStorage.getItem('nat') ? localStorage.getItem('nat')  : '';
    const config = {
        headers: { 
            Authorization: authToken
        }
    }
    return await axios.get(`${ENV.API_URL}/users/profile`, config);
}