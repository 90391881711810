import React, { Component } from 'react';

// Common components
import ImageToolComponent from '../components/ImageTool';

class ImageTool extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  /**
   * @description to handle toggle button of image tools ( for greyscale and revert ) click
   */
  handleClick() {
    const { value, variant } = this.props;
    if(variant === 'toggle'){
      this.handleChange({}, value === 0 ? 100 : 0);
    } else {
      this.setState((state) => ({
        open: !state.open,
      }));
    }
  }

  /**
   * @description to handle range values of image tools ( for brightness, contrast and saturation)
   */
  handleChange(e, val) {
    const { onChange } = this.props;
    if(typeof val === 'number'){
      onChange(val);
    } else if(e.target && e.target.value){
      onChange(Number(e.target.value));
    }
  }

  /**
   * @description render view for ( greyscale/revert toggle buttons and brightness/contrast/saturation range sliders)
   */
  render() {
    const { 
      windowId,
      type, 
      label,
      containerId,
      min,
      max,
      value,
      children,
      variant
    } = this.props;
    const { open } = this.state;

    const id = `${windowId}-${type}`;

    const toggled = variant === 'toggle' && value > 0;

    return (
      <ImageToolComponent
        labelId={id}
        label={label}
        containerId={containerId}
        handleClick={this.handleClick}
        open={open}
        toggled={toggled}
        handleChange={this.handleChange}
        max={max}
        min={min}
        value={value}
      >
        {children}
      </ImageToolComponent>
    );
  }
}

export default ImageTool;