import React from 'react';
import Button from '../../components/Button';

// icons
import flipImageIcon from '../../../images/icon/flip-image-icon.svg';

/*
 * @description render view for image flip button
*/
export const ImageFlip = (props) => {
  const {
    flipped, label, ...otherProps
  } = props;

  return (
    <div>
      <Button
        title={label}
        {...otherProps}
        className="MuiButtonBase-root MuiIconButton-root"
        style={{ backgroundColor: (flipped) ? 'rgba(0, 0, 0, 0.25)' : '' }}
      >
        <img
          className="MuiSvgIcon-root"
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
          src={flipImageIcon} />
      </Button>
    </div>
  );

}