import "../../src/styles/style.scss";

import { miradorConfig } from "../../src/lib/utils";
import DocumentViewer from "../../src/app/documentViewer";

import TopBarSearchPlugin from '../../src/app/topBarSearchPlugin';
import TranscriptionPlugin from '../../src/app/transcriptionPlugin';
import DocumentSearch from "../../src/app/documentSearch";
import DocumentSearchDialogPlugin from "../../src/app/documentSearchDialog";
import DocumentZoomLockPlugin from "../../src/app/documentZoomLockPlugin";
import ImageToolsMenuPlugin from '../../src/app/ImageToolsMenuPlugin';
import ImageToolsPlugin from '../../src/app/ImageToolsPlugin';

DocumentViewer.prototype.init(
    miradorConfig(),
    [
        TopBarSearchPlugin,
        TranscriptionPlugin,
        DocumentSearch,
        DocumentSearchDialogPlugin,
        DocumentZoomLockPlugin,
        ImageToolsMenuPlugin,
        ImageToolsPlugin
    ]
);
