import React from 'react';
import Button from '../../components/Button';

// icons
import rotateRightIcon from '../../../images/icon/rotate-right-icon.svg';
import rotateLeftIcon from '../../../images/icon/rotate-left-icon.svg';
/*
 * @description render view for image rotation buttons
*/

export const ImageRotation = (props) => {
  const { label, variant, ...otherProps } = props;

  return (
    <div>
      <Button
        title={label}
        {...otherProps}
        className="MuiButtonBase-root MuiIconButton-root"
      >
        <img 
          className="MuiSvgIcon-root"
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
          src={ variant === 'left' ? rotateRightIcon : rotateLeftIcon} />
      </Button>
    </div>
  );

}