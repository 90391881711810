import { createSelector } from 'reselect';

export function getWindows(state) {
    return state.windows || {};
}
/** */

export const getLatestWindowId = createSelector([getWindows], function (windows) {
    if(Object.keys(windows).length > 0){
        return Object.values(windows)[Object.keys(windows).length - 1].id;
    } else {
        return '';
    }
});
