export const CONSTANTS = {
    DEFAULT_PAGE: 1,
    DEFAULT_NUMBER_OF_ITEMS: 0,
    NO_TRANSCRIPTION_EXISTS: 'No Transcriptions exists',
    STATIC_DOCUMENTS_COUNT: 10000,
    VIEWS: [
        { behaviors: 'individuals', key: 'single' },
        { behaviors: 'paged', key: 'book' },
    ],
    DATE_REGEX: {
      FULL_DATE: /^\d{4}-\d{2}-\d{2}$/g,
      YEAR: /^\d{4}$/g,
      MONTH_AND_DAY: /^\d{2}/g,
    },
    BRIGHTNESS: {
      MIN: 0,
      MAX: 200
    }
};