import React, { Component } from 'react';
import searchIcon from "../images/search-icon.png";
import searchIconGray from "../images/search-icon-gray.png";
import closeIconGray from "../images/close-icon-outline-gray.png";
import Mirador from "../lib/mirador/mirador.min.js";

const actions = Mirador.actions;

const topBarSearchReducer = (state = {}, action) => {
    if (action.type === 'mirador/REMOVE_WINDOW') {
        localStorage.removeItem(`${action.windowId}-type`);
        return {
            ...state
        };
    }
    return state;
};

const mapDispatchToProps = (dispatch) => ({
    openSideBar: (windowId) => dispatch({ type: 'mirador/TOGGLE_WINDOW_SIDE_BAR', windowId: windowId }),
    openSearchPanel: (windowId, payload) => dispatch({ type: 'mirador/ADD_COMPANION_WINDOW', windowId: windowId, payload: payload }),
    searchAnnotations: (windowId, companionWindowId, searchId, query) => dispatch(actions.fetchSearch(windowId, companionWindowId, searchId, query))
})


const mapStateToProps = (state) => ({
    state: state
});


class TopBarSearchPlugin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: 'all_words'
        };
        this.updateOptionInMirador();

        this.viewerSearch = React.createRef();
        // bind method to "this" to access state in it
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
    }

    componentWillReceiveProps() {
        if (localStorage.getItem('term')) {
            this.setState({
                value: localStorage.getItem('term')
            })
        }
        if (localStorage.getItem('type')) {
            this.setState({
                type: localStorage.getItem('type')
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { value, type } = this.state;
        if (prevState.value !== value) {
            if (localStorage.getItem('type') && localStorage.getItem('term')) {
                setTimeout(() => {
                    if (type && value) {
                        this.openSideBar();
                        localStorage.removeItem('term');
                        localStorage.removeItem('type');
                    }
                }, 500);
            }
        }
    }

    /**
     * @description handle search type change
     */
    handleTypeChange() {
        this.setState({
            type: event.target.value
        });
        this.updateOptionInMirador();
    }

    /**
     * @description handle search value change
     */
    handleValueChange() {
        this.setState({
            value: event.target.value
        })
    }

    /**
     * @description handle search form submit event
     */
    handleFormSubmit() {
        event.preventDefault();

        const { value } = this.state;
        if (value) {
            this.openSideBar();
        }
    }

    /**
     * @description open side bar 
     */
    openSideBar() {
        const { state, targetProps } = this.props;
        const { windows } = state;
        const isSideBarOpen = windows[targetProps.windowId].sideBarOpen;

        if (isSideBarOpen) {
            this.openSearchPanel(targetProps.windowId);
        } else {
            this.props.openSideBar(targetProps.windowId);
            this.openSearchPanel(targetProps.windowId);
        }
    }

    updateOptionInMirador() {
        setTimeout(() => {
            const { targetProps } = this.props;
            const { windowId } = targetProps;

            const { type } = this.state;
            localStorage.setItem(`${windowId}-type`, type);
        }, 100);
    }

    /**
     * 
     * @param {string} windowId 
     * @description open side bar search panel
     */
    openSearchPanel(windowId) {
        const windowContainer = document.getElementById(windowId);
        setTimeout(() => {
            // click on search button present in sidebar
            const sideBarSearchButton = windowContainer.querySelector(
                "button[aria-label='Search']"
            );
            sideBarSearchButton && sideBarSearchButton.click();
            this.setSearchValue(windowId);
        }, 500);
    }

    /**
     * 
     * @param {string} windowId 
     * @description set top bar search value in panel search input
     */
    setSearchValue(windowId) {
        try {
            const windowContainer = document.getElementById(windowId);
            setTimeout(() => {
                const searchInput = windowContainer.querySelector(
                    "input[aria-autocomplete='list']"
                );
                if (searchInput) {
                    const { value, type } = this.state;
                    this.showHideSearchForm('close');

                    this.updateOptionInMirador();

                    // submit button click of material input
                    const { state, searchAnnotations } = this.props;
                    const companionWindowId = this.getSearchCompanionWindowId(state.windows[windowId].companionWindowIds);
                    const service = this.getServiceURL(windowId);

                    const searchId = `${service}?q=${value}&searchOption=${type}`;
                    searchAnnotations(windowId, companionWindowId, searchId, value);
                }
            }, 200);
        } catch (error) {
            console.log(error);
        }
    }

    getSearchCompanionWindowId(ids) {
        const { state } = this.props;
        const { companionWindows } = state;
        return ids.filter(id => companionWindows[id].content === 'search')[0];
    }

    getServiceURL(windowId) {
        const { state } = this.props;
        const { windows, manifests } = state;
        const { service } = manifests[windows[windowId].manifestId].json;

        return service[0]['@id'] || service[1]['@id'];
    }

    /**
     * 
     * @param {HTMLElement} element 
     * @param {string} value 
     * @description inject search value and bubble up event for material input to work correctly
     */
    setNativeValue(element, value) {
        try {
            const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
            const prototype = Object.getPrototypeOf(element);
            const prototypeValueSetter = Object.getOwnPropertyDescriptor(
                prototype,
                'value'
            ).set;
            if (valueSetter && valueSetter !== prototypeValueSetter) {
                prototypeValueSetter.call(element, value.split('"')[1]);
            } else {
                valueSetter.call(element, value.split('"')[1]);
            }
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * @description get search form HTML
     */
    getSearchFormOptions() {
        return (
            <select className="form-control" onChange={this.handleTypeChange} value={this.state.type}>
                <option value="all_words">All words</option>
                <option value="any_word">Any word</option>
                <option value="exact_phrase">Exact phrase</option>
                <option value="wildcard">Wildcard</option>
            </select>
        );
    }

    /**
     * @description get search form HTML
     */
    getSearchForm() {
        return (
            <form onSubmit={this.handleFormSubmit}>
                <div className="input-group mb-2 mt-2">
                    {this.getSearchFormOptions()}
                    <input className="form-control" aria-describedby="search-input" placeholder="Search inside this document"
                        aria-label="Search" value={this.state.value || ''} onChange={this.handleValueChange} />
                    <div className="input-group-append">
                        <button className="btn-search" type="submit">
                            <img src={searchIcon} height="20px" />
                        </button>
                    </div>
                </div>
            </form>
        );
    }

    /**
     * @description get search form HTML for small window width
     */
    getMobileSearchForm() {
        return (
            <form onSubmit={this.handleFormSubmit} className="m-2">
                {this.getSearchFormOptions()}
                <div className="input-group">
                    <input className="form-control" aria-describedby="search-input" placeholder="Search inside this document"
                        aria-label="Search" value={this.state.value || ''} onChange={this.handleValueChange} />
                    <div className="input-group-append">
                        <button type="submit" className="btn p-0 bg-transparent" variant="light">
                            <img src={searchIconGray} height="30px" />
                        </button>
                        <button type="click" className="btn p-0 bg-transparent" variant="light" onClick={() => { this.showHideSearchForm('close') }}>
                            <img src={closeIconGray} height="40px" />
                        </button>
                    </div>
                </div>
            </form>
        );
    }

    /**
     * @description toggle search bar for small window width
     */
    showHideSearchForm(status) {
        const searchFormContainer = this.viewerSearch.querySelector('.m-search-bar');
        if (searchFormContainer) {
            if (status === 'close') {
                searchFormContainer.classList.remove('d-block');
            } else {
                searchFormContainer.classList.add('d-block');
            }
        }
    }

    /**
     * 
     * @param {string} windowId 
     * @description provide search input based in window width
     */
    renderSearchInput(windowId) {
        var manifestId = this.props.state.windows[windowId].manifestId;
        var hasServices = this.props.state.manifests[manifestId] ? this.props.state.manifests[manifestId].json : [];
        if (hasServices && hasServices.service && hasServices.service.length > 0) {
            const windowContainer = document.getElementById(windowId);
            if (windowContainer) {
                if (windowContainer.clientWidth <= 968) {
                    return (
                        <div className="mr-2">
                            <img src={searchIconGray} onClick={() => { this.showHideSearchForm('open') }} />
                            <div className="m-search-bar">
                                {this.getMobileSearchForm()}
                            </div>
                        </div>
                    );
                } else {
                    return this.getSearchForm();
                }
            }
        }
    }

    /**
     * @description render method
     */
    render() {
        const { targetProps } = this.props;
        return (
            <div className="viewer-search" ref={inst => { this.viewerSearch = inst }}>
                {this.renderSearchInput(targetProps.windowId)}
                <this.props.TargetComponent {...targetProps} windowId={targetProps.windowId} />
            </div>
        );
    }
}

export default {
    target: 'WindowTopBarPluginArea',
    mode: 'wrap',
    name: 'TopBarSearchPlugin',
    component: TopBarSearchPlugin,
    mapDispatchToProps,
    mapStateToProps,
    reducers: {
        topBarSearchReducer: topBarSearchReducer,
    },
};
