import React, { Component } from "react";
import Pagination from "react-js-pagination";
import prevPageIcon from "../../images/chevron-left-icon-color.png";
import nextPageIcon from "../../images/chevron-right-icon-color.png";
import firstPageIcon from "../../images/skip-to-start-filled.png";
import lastPageIcon from "../../images/end-filled.png";
import { CONSTANTS } from "../../lib/constants";

export default class SearchPagination extends Component {

    constructor(props) {
        super(props);

        this.handlePageChange = this.handlePageChange.bind(this);
    }

    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });

        const { pageChange } = this.props;

        pageChange && pageChange(pageNumber);
    }

    getDocumentsCountMessage({ totalItems, totalDocumentsCount }) {
        if (totalDocumentsCount >= CONSTANTS.STATIC_DOCUMENTS_COUNT) {
            return <p>Total Documents: Showing <span>{this.getFormattedNumber(totalItems)} </span> of
            <span> {this.getFormattedNumber(totalDocumentsCount)}</span></p>;
        }
        return <p>Total Documents: <span>{totalItems}</span></p>;
    }

    getFormattedNumber(value) {
        return new Intl.NumberFormat().format(value);
    }

    render() {
        const { activePage, itemsPerPage, totalItems, hideTotal, pageRangeDisplayed } = this.props;
        return (
            <div className="pagination w-100 mt-2">
                {!hideTotal && this.getDocumentsCountMessage(this.props)}
                {totalItems !== 0 && (<Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={activePage}
                    itemsCountPerPage={itemsPerPage}
                    totalItemsCount={totalItems}
                    onChange={this.handlePageChange}
                    pageRangeDisplayed={pageRangeDisplayed}
                    firstPageText={<img src={firstPageIcon} alt="#"/>}
                    prevPageText={<img src={prevPageIcon} alt="#"/>}
                    nextPageText={<img src={nextPageIcon} alt="#"/>}
                    lastPageText={<img src={lastPageIcon} alt="#"/>}
                />)}
            </div>
        );
    }
}
