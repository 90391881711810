import React from 'react';
import PropTypes from 'prop-types';
import Mirador from "../lib/mirador/mirador.min";

import tuneImageToolsIcon from '../images/icon/tune-image-tools.svg';

// Mirador actions and selectors
const actions = Mirador.actions;
const selectors = Mirador.selectors;

const mapDispatchToProps = (dispatch) => ({
  updateWindow: (windowId, payload) => dispatch(actions.updateWindow(windowId, payload)),
});

const mapStateToProps = (state, { windowId }) => ({
  enabled: selectors.getWindowConfig(state, { windowId }).imageToolsEnabled || false,
  state: state
});

/**
  * @description Functional component for button to show or hide Image tools
  */
const ImageToolsMenuPlugin = ({
  enabled, handleClose, updateWindow, windowId,
}) => {

  /**
  * @description to enable OR disable image tools
  */
  const handleClickOpen = () => {
    handleClose();
    updateWindow(windowId, { imageToolsEnabled: !enabled });
  };

  return (
    <li onClick={handleClickOpen} className="MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button image-tools-li" tabindex="-1" role="menuitem" aria-disabled="false">
      <div className="MuiListItemIcon-root">
      <img src={tuneImageToolsIcon} className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" />
      </div>
      <div className="MuiListItemText-root">
        <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
        {enabled ? 'Hide image tools' : 'Show image tools'}
        </span>
      </div>
    </li>
  );
};

ImageToolsMenuPlugin.propTypes = {
  enabled: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  updateWindow: PropTypes.func.isRequired,
  windowId: PropTypes.string.isRequired,
};

ImageToolsMenuPlugin.defaultProps = {
  enabled: true,
};

export default {
  target: 'WindowTopBarPluginMenu',
  mode: 'add',
  name: 'ImageToolsMenuPlugin',
  component: ImageToolsMenuPlugin,
  mapDispatchToProps,
  mapStateToProps,
};