import { getUserProfile } from "../services/userService";
import { CONSTANTS } from "./constants";

// Mirador
import Mirador from "../lib/mirador/mirador.min";
const selectors = Mirador.selectors;

export const miradorConfig = () => {
  return {
    id: 'demo',
    window: {
      allowClose: true,
      allowFullscreen: true,
      // Configure if windows can be maximized or not
      allowMaximize: true,
      // Configure which sidebar panel is selected by default. Options: info, attribution, canvas, annotations
      defaultSideBarPanel: 'info',
      // Configure which viewing mode (e.g. single, book, gallery) for windows to be opened in
      defaultView: 'single',
      // Configure to hide the annotations panel in the WindowSideBarButtons
      hideAnnotationsPanel: false,
      // Configure if the window title is shown in the window title bar or not
      hideWindowTitle: false,
      sideBarOpenByDefault: false,
      hideSearchPanel: false,
      sideBarPanel: 'info',
      // Configure which panels are visible in WindowSideBarButtons
      panels: {
        layers: true,
        search: true
      },
      imageToolsEnabled: true,
      imageToolsOpen: false,
    },
    // set thumbnail strip location in window
    thumbnailNavigation: {
      defaultPosition: setThumbnailDefaultPosition(),
      height: 130,
      width: 100
    },
    // Configure controls based on view i.e mobile, tablet, laptop, etc.
    workspace: {
      showZoomControls: !isMobileView()
    }
  };
}

export const isMobileView = () => {
  if (
    window.navigator.userAgent &&
    (window.navigator.userAgent.match(/Android/i) ||
      window.navigator.userAgent.match(/webOS/i) ||
      window.navigator.userAgent.match(/iPhone/i) ||
      window.navigator.userAgent.match(/BlackBerry/i) ||
      window.navigator.userAgent.match(/Windows Phone/i))
  ) {
    return true;
  } else {
    return false;
  }
}

export const setThumbnailDefaultPosition = () => {
  if(!isMobileView()){
    return 'far-right'
  } else {
    return 'far-bottom'
  }
}

export const setCookie=(name, value)=>{
  document.cookie = `${name}=${value};path=''`;
};

export const getCookie =(name)=> {
  const cookieName = `${name}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return null;
};

export const resizedThumbnail = (url, size) => {
  const tempURL = url.split('full');
  if (!url.includes('document.png')) {
    url = `${tempURL[0]}full/${size},/0/default.jpg`;
  }
  return url;
}

export const makeFirstLetterCapital = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export const getFormattedCanvasId = (id) => {
  const regex = /[^/]*$/;
  return regex.exec(id)[0];
}

export const getFormattedManifestId = (id) => {
  return id.split('/')[id.split('/').length - 2];
}

/**
* @description To manage side panel for windows, currently not in use ( 02-04-20 ) 
* to replace book view
*/
export const openInfoPanel = (layout, props, flag) => {
  try {
    let workspace = null;

    // Note: In initial call props is mirador object after that it will be react component props
    if (flag === 'initial') {
      const { store } = props;
      workspace = store.getState().workspace;
    } else {
      workspace = props.workspace;
    }

    // if workspace object is not present then do nothing
    if (!workspace || isMobileView()) {
      return;
    }

    // check layout object for first or second window object
    if (layout.first || layout.second) {
      setInfoPanel(layout.first, props);
      setInfoPanel(layout.second, props);
    }
    // if window contains only one document add to opened windows array
    else {
      setInfoPanel(layout, props);
    }
  } catch (error) {
    console.log(error);
  }
}

/**
* @description To set Info panel open on right side of window
*/
export const setInfoPanel = (panel, props) => {
  try {
    const openedWindows = JSON.parse(localStorage.getItem('openedWindows')) || [];
    const { openSidePanel } = props;

    if (openedWindows.includes(panel)) return;

    // default payload for info panel
    const payload = {
      content: 'info',
      position: 'right'
    };

    if (typeof panel === 'string') {
      openedWindows.push(panel);
      localStorage.setItem('openedWindows', JSON.stringify(openedWindows));

      // check if props have openSidePanel function
      if (openSidePanel) {
        openSidePanel(panel, payload);
      }
      // else use store to dispatch action
      else {
        const { store, actions } = props;
        store.dispatch(actions.addCompanionWindow(panel, payload));
      }
    }
    // if layout second is type object than call function again
    else {
      openInfoPanel(panel, props);
    }
  } catch (error) {
    console.log(error);
  }
}

export const createRequestObjectForDocuments = (searchObject, filterObject, isTypeSearch) => {
  const obj = {};

  if (isTypeSearch) {
    obj.page = searchObject.page;
    obj.perPage = searchObject.perPage;
    obj.fieldToSort = searchObject.fieldToSort;
    obj.order = searchObject.order;
  } else {
    obj.page = filterObject.page;
    obj.perPage = filterObject.perPage;
  }

  obj.searchOption = searchObject.searchOption;
  obj.searchType = searchObject.searchType;

  filterObject.term !== '' && (obj.term = filterObject.term);
  !obj.filter && (obj.filter = {});
  filterObject.creator !== '' && (obj.filter.creator = filterObject.creator);
  filterObject.contributor !== '' && (obj.filter.contributor = filterObject.contributor);
  filterObject.collection !== '' && (obj.filter.collection = filterObject.collection);
  filterObject.date !== '' && (obj.filter.date = filterObject.date);
  filterObject.subject !== '' && (obj.filter.subject = filterObject.subject);
  filterObject.type !== '' && (obj.filter.type = filterObject.type);
  filterObject.category !== '' && (obj.filter.category = filterObject.category);
  filterObject.call_number !== '' && (obj.filter.call_number = filterObject.call_number);
  filterObject.coverage !== '' && (obj.filter.coverage = filterObject.coverage);
  filterObject.tag !== '' && (obj.filter.tag = filterObject.tag);

  return obj;
};

export const createRequestObjectForFilters = (filterObject) => {
  const obj = {};
  obj.page = filterObject.moreFilters.page;
  obj.perPage = filterObject.moreFilters.perPage;

  filterObject.moreFilters.filterTerm !== '' && (obj.filterTerm = filterObject.moreFilters.filterTerm)
  return obj;
}

export const validateUser = () => {
    return getUserProfile().then(() => {
      return true;
    }).catch((error) => {
      if(error.response.status === 401){
        return false;
      }
    });
}

export const getViewConfigs = (manifestBehaviors) => {
  let { key } = CONSTANTS.VIEWS.find(view => manifestBehaviors.includes(view.behaviors))
  return key
}

// To get default applied image filters for canvas
export const getFilterConfig = (state, windowId) => {
  const canvasWorld = selectors.getCurrentCanvasWorld(state, { windowId });
  const filters = ['brightness','contrast','flip','greyScale','invert','saturation'];
  let filterConfig = {};
  if(canvasWorld.canvases && canvasWorld.canvases.length > 0 && canvasWorld.canvases[0].canvas.__jsonld){
    filters.map((filter) => {
      if(Object.keys(canvasWorld.canvases[0].canvas.__jsonld).includes(filter)){
        if(['greyScale','saturation'].includes(filter)){
          const newFilter = filter === 'greyScale' ? 'grayscale' : 'saturate';
          let value = filter === 'greyScale' && canvasWorld.canvases[0].canvas.__jsonld[filter] ? 100 : 0;
          value = filter === 'saturation' ? canvasWorld.canvases[0].canvas.__jsonld[filter] : value;
          filterConfig[newFilter] = value;
        } else if(filter === 'invert'){
          filterConfig[filter] = canvasWorld.canvases[0].canvas.__jsonld.invert ? 100 : 0
        } else {
          filterConfig[filter] = canvasWorld.canvases[0].canvas.__jsonld[filter];
        }
      }
    })
  }
  return filterConfig;
}