import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Mirador from '../lib/mirador/mirador.min.js';
import { getFormattedManifestId,getCookie } from "../lib/utils";
import uuidV4 from "uuid/v4";
import { getManifestData} from "../services/documentService";
import { getUserProfile } from "../services/userService.js"
import { getUserChatHistory } from "../services/chatService.js"

const actions = Mirador.actions;

export default class ChatBotPlugin extends Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.windowId = props.targetProps.windowId;
        this.handleSelection = this.handleSelection.bind(this);
    }

    /**
     * 
     * @param {object} prevProps 
     * @description life cycle method to compare previous and current state
     */
    componentDidUpdate(prevProps) {
        this.detectTabChange(prevProps);
        this.detectCanvasChange(prevProps);
        this.detectSideBarToggle(prevProps);
        this.detectViewTypeChange(prevProps);
        this.detectWindowMinimiseMaximise();
    }


    /**
     * @param {object} prevProps 
     * @description to detect last tab selected and side panel opened
     */
    detectSideBarToggle(prevProps) {
        const { state, targetProps } = this.props;
        if (state.windows[targetProps.windowId] && state.windows[targetProps.windowId].sideBarOpen !== prevProps.state.windows[targetProps.windowId].sideBarOpen) {

            if (state.transcription && state.transcription[targetProps.windowId].chatbot) {
                this.deselectTabs();
                setTimeout(() => {
                    this.selectTab();
                }, 200);
            }
        }
    }

    /**
     * 
     * @param {object} prevProps 
     * @description to detect canvas change
     */
    detectCanvasChange(prevProps) {
        if (document.getElementsByClassName('mirador-canvas-count')[0]) {
            const pageIndex = document.getElementsByClassName('mirador-canvas-count')[0].innerText.split(' ')[0];
            parent.postMessage({ pageIndex: pageIndex }, ENV.USER_BASE_URL);
        }
        const { state, targetProps } = this.props;
        if (state.windows[targetProps.windowId] && state.windows[targetProps.windowId].canvasId !== prevProps.state.windows[targetProps.windowId].canvasId) {
            if (state.transcription && state.transcription[targetProps.windowId] && state.transcription[targetProps.windowId].transcription) {
                this.selectTab();
            }
        }
    }

    /**
     * 
     * @param {object} prevProps 
     * @description to detect sidebar tab change
     */
    detectTabChange(prevProps) {
        const { targetProps, state } = this.props;
        // this.selectTab();

        // check if chatbot object is empty
        if (!state.transcription || !state.transcription[targetProps.windowId] || !prevProps.state.transcription[targetProps.windowId]) {
            return;
        }

        // check if chatbot tab is click
        if (state.transcription[targetProps.windowId].chatbot !== prevProps.state.transcription[targetProps.windowId].chatbot) {
            if (state.transcription[targetProps.windowId].chatbot) {
                this.selectTab();
            } else {
                this.deselectTabs();
                this.manipulateState();
            }
        }
    }

    /**
     * @description manipulate state when tab is changed from chatbot to search
     */
    manipulateState() {
        const { targetProps, state } = this.props;
        if (state.transcription && state.transcription[targetProps.windowId].content === 'search' && !state.transcription[targetProps.windowId].chatbot) {
            this.props.openSearchPanel(targetProps.windowId, {
                content: 'annotations',
                position: 'left'
            })
            setTimeout(() => {
                this.props.openSearchPanel(targetProps.windowId, {
                    content: 'search',
                    position: 'left'
                })
            }, 10);
        }
    }

    /**
     * @description handle button selection
     */
    handleSelection() {
        const { targetProps } = this.props;

        this.props.openSearchPanel(targetProps.windowId, {
            content: 'search',
            position: 'left',
            chatbot: true
        });
    }

    /**
     * @description select chatbot tab
     */
    selectTab() {
        const { sideBarRef } = this.props;
        setTimeout(() => {
            if (sideBarRef === null) {
                return;
            }
            const refs = ReactDOM.findDOMNode(sideBarRef);
            if (refs) {
                const tab = refs.querySelector('.chatbot-btn');
                const transcriptionCompanionArea = sideBarRef.offsetParent.getElementsByClassName('mirador-companion-area-left')[0];
                if (transcriptionCompanionArea) {
                    const header = transcriptionCompanionArea.querySelector('.MuiTypography-h3');
                    if(header && header!==null){
                    header.classList.add('mt-3');
                    header.classList.add('mb-2');
                    header.innerHTML = '<strong class="MuiTypography-root MuiTypography-body1">Chat With Document</strong>';  
                    header.nextElementSibling.classList.add('d-none');            
                    }
                    const headerControl = transcriptionCompanionArea.querySelector('.mirador-companion-window-title-controls');
                    if(headerControl && headerControl!==null){
                    headerControl.classList.add('d-none');
                    }
                    const body = transcriptionCompanionArea.querySelector('.MuiPaper-elevation0');
                    if(body && body!==null){
                    body.innerHTML=`<div class="container" style="height:50vh;display:flex;justify-content:center;align-items:center">
                    <span style="font-weight:bold">Loading ...</span>
                    </div>`      
                }
                }
                this.deselectTabs();
                this.activateTab(tab);
                this.checkModalCondition()
                this.getUserChatHistory();
            }
        }, 100);
    }

    /**
     * selects the given tab
     * @param {HTMLElement} tab the tab to activate
     */
    activateTab(tab) {
        tab.removeAttribute('tabIndex');
        tab.setAttribute('aria-selected', 'true');
        tab.focus();
        this.getUserChatHistory();
    }

    /**
     * @description deselect all tabs when selection changed
     */
    deselectTabs() {
        const { sideBarRef } = this.props;
        if (sideBarRef === null) {
            return;
        }
        const refs = ReactDOM.findDOMNode(sideBarRef);
        this.tabs = Array.from(refs.querySelectorAll('button[role=tab]'));

        this.tabs.forEach(tab => {
            tab.setAttribute('tabindex', '-1');
            tab.setAttribute('aria-selected', 'false');
            tab.classList.add('tab-unselected');
            tab.classList.remove('Mui-selected');
        });
    }

    async getUserId () {
        let userChatId;
        if(localStorage.getItem('nat') && !localStorage.getItem('userChatId')){
            try {
            const user = await getUserProfile();
            if(user && user.data) {
                console.log('userdata', user.data);
                userChatId = user.data.id;
                localStorage.setItem('userChatId', userChatId);
            }
            } catch (err) {
              console.log('Profile Error', err);
            }
        } else {
            if(localStorage.getItem('userChatId')) {
                userChatId = localStorage.getItem('userChatId');
            } else {
                userChatId = uuidV4();
                localStorage.setItem('userChatId', userChatId);
            }
        }
        return userChatId;
    }
    
    isScrolledToBottom(element) {
        return element.scrollHeight - element.clientHeight <= element.scrollTop + 1;
    }

    /**
     * @description get chatbot data from server
     */
    async getUserChatHistory(page = 1, existingData = []) {

        const { state, targetProps,sideBarRef } = this.props;
        const manifestId = state.windows[targetProps.windowId].manifestId;
        

        const requestObject = {
            page,
            manifestId: getFormattedManifestId(manifestId),
            visitorId: await this.getUserId()
        }

        const userHistory = await getUserChatHistory(requestObject);
        console.log('HISTORY', userHistory);
        const refs = ReactDOM.findDOMNode(sideBarRef);
        if(refs !==null){
        const selectedTab = refs.querySelector('[aria-selected="true"]');
        const transcriptionTab = selectedTab ? selectedTab.classList.contains('transcription-btn') : false
        if (transcriptionTab) {
            return 
        }
    }
        if(userHistory.data && userHistory.data.data && userHistory.data.data.length > 0){
            await this.replaceHTML([...existingData,...userHistory.data.data], page);
            return false;
        } else if (userHistory.data && userHistory.data.data && userHistory.data.data.length === 0 && page === 1) {
            await this.replaceHTML();
        } else {
            return true;
        }
    }

    addUserMessages(historyMessages, documentRef, openCanvas) {
        const messages = documentRef.querySelector(".messages");
        const { state, targetProps: { windowId } } = this.props;
        const manifest =
        state.manifests[state.windows[windowId].manifestId] && state.manifests[state.windows[windowId].manifestId].json  
      let canvases=[];
          canvases= manifest.items.map(item=>{
              const canvas=item.id.split('/');
              const canvasId=canvas[canvas.length-1];
              return {id:canvasId,label:item.label}
          });
        if(historyMessages && historyMessages.length > 0) {
            for (const message of historyMessages) {
                if(message.type === 'User') {
                    const messageElement = document.createElement(`div`);
                    messageElement.classList.add("message", "user-message");
                    messageElement.innerHTML = `<p>${message.messageContent}</p>`
                    messages.appendChild(messageElement);
                } else {
                    const messageElement = document.createElement(`div`);
                    messageElement.classList.add("message", "bot-message");
                    const regex = /Source: \d+ ([\w-]+)/g;
                    const matches = message.messageContent.matchAll(regex);
                    const sourceIds = [];
    console.log(sourceIds,'sourceIds');
    console.log(matches,'matches');
                    for (const match of matches) {
                        sourceIds.push(match[1]);
                    }
    
                    let text = message.messageContent.replace(/<br>/g, "").replace(/<p>.*?<\/p>/g, "");
                    text = text.concat('<item class="chatbot-container">')
                    sourceIds.forEach((id) => {
                        const index=canvases.findIndex(canvas=>canvas.id===id);
                        if(index>-1){                 
                        text = text.concat(`<br><button class="btn btn-primary canvas-btn" id="${id}">Page : ${canvases[index].label}</button>`)
                     }     })
                    text = text.concat('</item>')
                    messageElement.innerHTML = `<div>${text}</div>`
                    const canvasButtons = documentRef.querySelectorAll('.canvas-btn');
    
                    canvasButtons.forEach(button => {
                        button.onclick = openCanvas;
                    });
                    messages.appendChild(messageElement);
                }
            }
        }
    }

    checkModalCondition(){
        const { sideBarRef,state ,targetProps: { windowId }} = this.props;
        const refs = ReactDOM.findDOMNode(sideBarRef);
        if(refs !==null){
        const selectedTab = refs.querySelector('[aria-selected="true"]');
        const chatbotTab = selectedTab ? selectedTab.classList.contains('chatbot-btn') : false
        if (!chatbotTab) {
            return
        }else{
            if(getCookie('agreedToTerms')===null||getCookie('agreedToTerms')==='false'){
            window.parent.postMessage({action:"Show Popup"}, '*');
            }
        }
    }    
    }

    /**
     * 
     * @description replace companion window HTML with chatbot
     */
    async replaceHTML(userHistory = [], page = 1) {
        try {
            const that = this;
            let historyEnded = false;
            const { sideBarRef, state, targetProps: { windowId } } = this.props;
            const manifestId = getFormattedManifestId(state.windows[windowId].manifestId);
            const welcomeMessage =
              state.windows[windowId] &&
              state.windows[windowId].aiRelatedDetails &&
              state.windows[windowId].aiRelatedDetails.aiSettingsData
                ? state.windows[windowId].aiRelatedDetails.aiSettingsData
                    .welcomeMessage
                : 'Hello, how can I assist you. I am designed to help you think, not to do your thinking for you, so please use the sources below to verify my responses.';
                const manifest =
              state.manifests[state.windows[windowId].manifestId] && state.manifests[state.windows[windowId].manifestId].json  ;
            let canvases=[];
                canvases= manifest.items.map(item=>{
                    const canvas=item.id.split('/');
                    const canvasId=canvas[canvas.length-1];
                    return {id:canvasId,label:item.label}
                });
        
            const documentRef = document.querySelector(`section#${windowId}`)
            let divId;
            let isBotTyping = false;

            if (!sideBarRef || sideBarRef === null) {
                return;
            }
            const headTag = document.querySelector('head');
            const mainScriptElement = document.createElement('script');
            const canvasSourceScriptElement = document.createElement('script');
            const openCanvas = async function (e) {
                const selectedCanvasId = e.target.id
                const { windowId, props }  = that
                const currentWindow = props.state.windows[windowId];
                const currentCanvas = currentWindow.canvasId;

                let parts = currentCanvas.split("/");
                parts[parts.length - 1] = selectedCanvasId;

                const action = actions.setCanvas(windowId, parts.join("/"))
                props.dispatch(action);

            }
            canvasSourceScriptElement.innerHTML = openCanvas.toString();
            headTag.appendChild(canvasSourceScriptElement);

            const sendMessage = async function (query) {
                const chatContainer = documentRef.querySelector(".chat-container");
                let typingIndicator = documentRef.querySelector("#typing");
                if (!isBotTyping) {
                    isBotTyping = true;
                    typingIndicator.classList.remove("hide");
                    chatContainer.scrollTo(0, chatContainer.scrollHeight);
                }
                let messageElement;
                let messages = documentRef.querySelector(".messages");
                const userQuery = query && typeof query === 'string' ? query : documentRef.querySelector('.chat-user-msg').value;
                messageElement = document.createElement(`div`);
                messageElement.classList.add("message", "user-message");
                messageElement.innerHTML = `<p>${userQuery}</p>`
                messages.appendChild(messageElement);
                documentRef.querySelector('.chat-user-msg').value = ''
                chatContainer.scrollTo(0, chatContainer.scrollHeight);
                let userChatId = await that.getUserId();
                
                const postData = {
                    query: userQuery,
                    visitorId: userChatId,
                    manifestId: manifestId
                };
                const fetchOptions = {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },  
                    body: JSON.stringify(postData),
                  };
                const response = await fetch(`${ENV.API_URL}/chat/completion`, fetchOptions)
                const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
                const length = 6;
        
                for (var i = 0; i < length; i++) {
                    divId += characters.charAt(Math.floor(Math.random() * characters.length));
                }

                const reader = response.body.getReader();
                const decoder = new TextDecoder();
                let done = false;
                let text = '';
                let allText = '';
                
                
                while (!done) {
                    const {value, done: doneReading} = await reader.read();
                    done = doneReading;
                    const chunkValue = decoder.decode(value);
                    text += chunkValue;
                    let messages = documentRef.querySelector(".messages");
                    
                    if (documentRef.querySelector(`div#${divId}`)) {
                        messageElement = documentRef.querySelector(`div#${divId}`);
                        messageElement.classList.add("message", "bot-message");
                        let existingText = messageElement.textContent.concat(` ${chunkValue}`);
                        messageElement.innerHTML = `<p>${existingText.replace(/<br>/g, "").replace(/<p>.*?<\/p>/g, "")}</p>`
                    } else {
                        messageElement = document.createElement(`div`);
                        messageElement.setAttribute("id", `${divId}`)
                        messageElement.classList.add("message", "bot-message");
                        messageElement.innerHTML = `${decodeURIComponent(`<p>${chunkValue}</p>`)}`
                        messages.appendChild(messageElement);
                    }
                    chatContainer.scrollTo(0, chatContainer.scrollHeight);
                }
                const regex = /Source: \d+ ([\w-]+)/g;
                allText = text;
                const matches = allText.matchAll(regex);
                const sourceIds = [];

                for (const match of matches) {
                    sourceIds.push(match[1]);
                }

                text = text.replace(/<br>/g, "").replace(/<p>.*?<\/p>/g, "");

                text = text.concat('<item class="chatbot-container">')
                sourceIds.forEach((id) => {
                    const index=canvases.findIndex(canvas=>canvas.id===id);
                    if(index>-1){
                    text = text.concat(`<button class="btn btn-primary canvas-btn" id="${id}">Page : ${canvases[index].label}</button>`)
                }})
                text = text.concat('</item>')
                messageElement.innerHTML = `<div>${text}</div>`;
                const canvasButtons = documentRef.querySelectorAll('.canvas-btn');

                canvasButtons.forEach(button => {
                    button.onclick = openCanvas;
                });

                messages.appendChild(messageElement);
                if (isBotTyping) {
                    let typingIndicator = documentRef.querySelector("#typing");
                    isBotTyping = false;
                    typingIndicator.classList.add("hide");
                }
                chatContainer.scrollTo(0, chatContainer.scrollHeight);
            };
            mainScriptElement.innerHTML = sendMessage.toString();
            
            headTag.appendChild(mainScriptElement);
            const transcriptionCompanionArea = sideBarRef.offsetParent.getElementsByClassName('mirador-companion-area-left')[0];
            if (transcriptionCompanionArea) {
                const header = transcriptionCompanionArea.querySelector('.MuiTypography-h3');
                header.classList.add('mt-3');
                header.classList.add('mb-2');
                header.innerHTML = '<strong class="MuiTypography-root MuiTypography-body1">Chat With Document</strong>';

                header.nextElementSibling.classList.add('d-none');

                const headerControl = transcriptionCompanionArea.querySelector('.mirador-companion-window-title-controls');
                headerControl.classList.add('d-none');

                const body = transcriptionCompanionArea.querySelector('.MuiPaper-elevation0');
                body.innerHTML = `<div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <div class="chat-container">
                        <div class="messages">
                            <div class="message bot-message">
                                <p>${welcomeMessage}</p>
                            </div>
                        </div>
                        <div id="typing" class="typing hide">Typing...</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="chat-input">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="input-group">
                        <input type="text" class="form-control chat-user-msg" placeholder="Type your message...">
                        <div class="input-group-append">
                          <button class="btn btn-primary custom-chat-btn"><svg viewBox="64 64 896 896" focusable="false" data-icon="send" width="1em" height="1em" fill="currentColor" aria-hidden="true"><defs><style></style></defs><path d="M931.4 498.9L94.9 79.5c-3.4-1.7-7.3-2.1-11-1.2a15.99 15.99 0 00-11.7 19.3l86.2 352.2c1.3 5.3 5.2 9.6 10.4 11.3l147.7 50.7-147.6 50.7c-5.2 1.8-9.1 6-10.3 11.3L72.2 926.5c-.9 3.7-.5 7.6 1.2 10.9 3.9 7.9 13.5 11.1 21.5 7.2l836.5-417c3.1-1.5 5.6-4.1 7.2-7.1 3.9-8 .7-17.6-7.2-21.6zM170.8 826.3l50.3-205.6 295.2-101.3c2.3-.8 4.2-2.6 5-5 1.4-4.2-.8-8.7-5-10.2L221.1 403 171 198.2l628 314.9-628.2 313.2z"></path></svg></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>`;
                

                const btn = documentRef.querySelector('.custom-chat-btn');
                btn.onclick = sendMessage;
                const inputElement = documentRef.querySelector('.chat-user-msg');
                inputElement.addEventListener('keyup', function (event) {
                    if (event.key === 'Enter') {
                        sendMessage();
                    }
                });

                const handleScroll = async function () {

                    const chatContainerRef = documentRef.querySelector(".chat-container")
                    const currentScrollPosition = chatContainerRef.scrollTop;
                    if (that.isScrolledToBottom(chatContainerRef) && !historyEnded) {
                        page = page+1;
                        historyEnded = await that.getUserChatHistory(page,userHistory);
                        documentRef.querySelector(".chat-container").scrollTop = currentScrollPosition;
                    }
                }

                documentRef.querySelector(".chat-container").addEventListener("scroll", handleScroll);

                if(userHistory && userHistory.length > 0) {
                    that.addUserMessages(userHistory, documentRef, openCanvas);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        const { targetProps } = this.props;
        const thisWindow = this.props.state.windows[Object.keys(this.props.state.windows)[0]]
        const userRole = thisWindow && thisWindow.userRole ? thisWindow.userRole : null ;
        return (
            <React.Fragment>
                {(() => {
                    if (targetProps.sideBarOpen && userRole === 'admin') {
                        return (
                            <button className="MuiTab-textColorPrimary chatbot-btn" aria-selected="false" tabIndex="1" aria-label="Chatbot" role="tab" onClick={this.handleSelection}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" id="chatbot">
                                    <path fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="20" d="m577.27 804.78-41.85.78-166.87 110.62c-.57.41-1.36 0-1.36-.7V804.34h-81.51c-59 0-106.81-47.82-106.81-106.81V394.45c0-58.99 47.81-106.81 106.81-106.81H738.33c58.99 0 106.81 47.82 106.81 106.81v303.08c0 58.99-47.82 106.81-106.81 106.81l-43.64.44H577.27z"></path>
                                    <circle cx="389.34" cy="448.2" r="61.34" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="20"></circle>
                                    <circle cx="635.34" cy="448.2" r="61.34" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="20"></circle>
                                    <path fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="20" d="M847.21 634.45V463.13h46.48c19.39 0 35.11 15.72 35.11 35.11v101.11c0 19.39-15.72 35.11-35.11 35.11h-46.48zM95.2 599.35V498.24c0-19.39 15.72-35.11 35.11-35.11h46.48v171.33h-46.48c-19.39-.01-35.11-15.73-35.11-35.11zm546.66 6.99c-8.57 61.51-64.53 109-132.32 109-67.77 0-123.75-47.49-132.32-109h264.64z"></path><circle cx="509.54" cy="149.59" r="41.94" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="20"></circle><path fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="20" d="M509.54 244.21v-52.68"></path>
                                </svg>
                            </button>
                        );
                    } else {
                        return null;
                    }
                })()}
            </React.Fragment>
        );
    }

    detectViewTypeChange(prevProps) {
        const { targetProps, state } = this.props;
        const { windows, chatbot } = state;
        if (!targetProps.windowId) {
            return false;
        }
        const windowId = targetProps.windowId;
        if (windows[windowId] && prevProps.state.windows[windowId]) {
            const windowData = windows[windowId];
            const currentView = windowData.view;
            const previousWindowData = prevProps.state.windows[windowId];
            const previousView = previousWindowData.view;
            let chatbotData = false;
            if (chatbot && chatbot[windowId]) {
                chatbotData = chatbot[windowId].chatbot;
            }
            if (currentView && previousView && chatbotData && currentView != previousView) {
                this.getUserChatHistory();
            }
        }
    }

    detectWindowMinimiseMaximise() {
        const { targetProps, state } = this.props;
        const { chatbot } = state;
        if (!targetProps.windowId) {
            return false;
        }
        const windowId = targetProps.windowId;
        const windowData = chatbot && chatbot[windowId];
        let chatbotData = false;
        if (chatbot && chatbot[windowId]) {
            chatbotData = chatbot[windowId].chatbot;
        }
        if (windowData && chatbotData) {
            if (windowData.windowScale === 'max' || windowData.windowScale === 'min') {
                this.props.updateTranscriptionData(windowId, { "windowScale": "none" })
                this.selectTab();
                return false;
            }
            if (windowData.setOtherWindowScale) {
                this.props.updateTranscriptionData(windowId, { "setOtherWindowScale": false })
                const transcriptionKeys = Object.keys(this.props.state.transcription);
                transcriptionKeys.forEach(element => {
                    if (element !== windowId) {
                        const otherWindow = this.props.state.transcription[element];
                        if (otherWindow.chatbot) {
                            this.props.updateTranscriptionData(element, { "windowScale": "min" });
                        }
                    }
                });
            }
        }
    }
}