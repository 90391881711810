import Mirador from '../lib/mirador/mirador.min.js';
import { isMobileView, getViewConfigs } from "../lib/utils";
import { getManifestData } from "../services/documentService";
import { CONSTANTS } from "../lib/constants";

const actions = Mirador.actions;
let canvasIndex = 0;
let downloadPDF = '';
export default class DocumentViewer {
    constructor() {
        this.mirador = null;
        this.documentData = null;
        this.openedWindows = null;
    }

    init(config, plugins) {
        window.onmessage = event => {
            if (event.origin !== ENV.USER_BASE_URL) {
                return;
            }
            if (event.data.di !== '') {
                this.documentData = event.data;
                this.openedWindows = [];
                this.initializeMirador(config, plugins);
                // get page number passed from UI and pass as canvas index and set in config of mirador viewer
                if (event.data.pageNo && Number(event.data.pageNo) > 0) { 
                    canvasIndex = +event.data.pageNo - 1; // set canvas index with minus 1 as page count is differeent from index
                    localStorage.setItem('currentCanvasIndex', canvasIndex);
                }
                if (event.data.nat && event.data.nat !== '') {
                    localStorage.setItem('nat', event.data.nat);
                }
                if (event.data.term && event.data.term !== '') {
                    localStorage.setItem('term', event.data.term);
                }
                if (event.data.type && event.data.type !== '') {
                    localStorage.setItem('type', event.data.type);
                }
                if (event.data.userChatId && event.data.userChatId !== '') {
                    localStorage.setItem('userChatId', event.data.userChatId);
                }
            }
        };
    }

    /**
     * 
     * @param {object} config 
     * @param {Array} plugins 
     * @description initialize mirador with config and plugins
     */
    initializeMirador(config, plugins) {
        this.mirador = Mirador.viewer(config, [
            ...plugins
        ]);

        localStorage.clear();
        this.loadDocument();
    }

    /**
     * @description load document in viewer
     */
    loadDocument() {
        const { store } = this.mirador;
        // Check if mirador instance is generated properly
        if (this.mirador && store.getState().windows !== {}) {
            const { store } = this.mirador;
            this.changeInitLabel();
            // load document using manifest Id
            store.dispatch(actions.addWindow({ manifestId: this.documentData.di,aiRelatedDetails:this.documentData.aiRelatedDetails,agreedToTerms: this.documentData.agreedToTerms ,downloadPDF: this.documentData.downloadPDF,userRole : this.documentData.userRole  }));
            // this.hideLoader();

            const { windows } = store.getState();

            const windowId = Object.keys(windows)[0];

            // set canvas index
            windows[windowId].canvasIndex = canvasIndex;

            // focus loaded window
            store.dispatch(actions.focusWindow(Object.keys(windows)[0]));

            //hide unwanted elements
            this.hideElements();         

            if (!isMobileView()) {
                this.setDocumentType(windows, store);
            }
        }
    }

    /**
     * hide unwanted elements
     */
    hideElements() {
        setTimeout(() => {
            let menuBtn = document.getElementById('menuBtn');
            let addBtn = document.getElementById('addBtn');
            let miradorLogo = document.getElementsByClassName(
                'mirador-workspace-control-panel'
            )[0].children[1];
            miradorLogo.style.display = 'none';
            addBtn.style.display = 'none';
            menuBtn.style.display = 'none';
            menuBtn.nextSibling.style.display = 'none';
        }, 1000);
    }

    changeInitLabel() {
        const { store } = this.mirador;
        store.subscribe(() => {
            const { windows } = store.getState();
            if (Object.keys(windows).length === 0) {
                setTimeout(() => {
                    let that = this;
                    let miradorLabel = document.getElementsByClassName('MuiTypography-root MuiTypography-h1 MuiTypography-alignCenter')[0];
                    if (miradorLabel) {
                        var btn = document.createElement("BUTTON");   // Create a <button> element
                        btn.className = "welcome-mirador-reload-btn";
                        btn.addEventListener("click", function () {
                            location.reload();
                        });
                        btn.innerHTML = "Refresh page to load original document";
                        miradorLabel.innerHTML = "";
                        miradorLabel.appendChild(btn);
                    }
                }, 0);
            }
        })
    }

    setDocumentType(windows, store) {
        let windowKeys = Object.keys(windows);
        let windowId = windowKeys[0];
        let manifestId = windows[windowId].manifestId;
        getManifestData(manifestId).then((response) => {
            if (response.status == 200) {
                let manifestData = response.data;
                let view = getViewConfigs(manifestData.behavior);

                downloadPDF = manifestData && manifestData.downloadPDF && manifestData.downloadPDF.pdfURL && manifestData.downloadPDF.isDownloadEnable
                                ? manifestData.downloadPDF.pdfURL
                                : '';

                store.dispatch(actions.setWindowViewType(windowId, view));
            }
        }).catch((error) => {
            console.log(error);
        })
    }
}
