import React from "react";

// Shared components
import AutocompleteInputBox from '../shared/components/AutocompleteInputBox';

class CustomDateField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      errorMessage: '',
      undated: false
    }
    
    if(props.value){
      this.setInitialDateValue(props.value);
    } else {
      this.state = {
        undated: false,
      }
      this.setEmptyFullDate();
    }
    this.onChangeYear = this.onChangeYear.bind(this);
    this.onSelectYear = this.onSelectYear.bind(this);
    this.onChangeMonth = this.onChangeMonth.bind(this);
    this.onSelectMonth = this.onSelectMonth.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onSelectDate = this.onSelectDate.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
  }

  // to set undated value based on date
  setInitialUndatedValue(){
    this.state.undated = this.isDefaultDate() ? true : false
  }

  // to get year value from full date
  getYearFromFullDate(date) {
    return date.split('-')[0].toString();
  }

  // to get month value from full date
  getMonthFromFullDate(date) {
    return date.split('-')[1].toString();
  }

  // to get day value from full date
  getDayFromFullDate(date) {
    return date.split('-')[2].toString();
  }

  // to get default year 0000
  getDefaultYearValue() {
    return '0000';
  }

  // to get default month/day 00
  getDefaultMonthOrDayValue() {
    return '00';
  }

  // Set initial date value with different scenarios
  setInitialDateValue(value) {
    if(value.toLowerCase() === 'undated'){
      this.state = {
        yearValue: '0000',
        monthValue: '00',
        dateValue: '00',
        undated: true
      }
    } else if(value !== ''){
      const partsOfDate = value.replaceAll('*','').split('-');
      this.state = {
        yearValue: partsOfDate[0] ? partsOfDate[0] === '0000' ? 'Unknown'  : partsOfDate[0] : '',
        monthValue: partsOfDate[1] ? partsOfDate[1] === '00' ? 'Unknown' : partsOfDate[1] : '',
        dateValue: partsOfDate[2] ? partsOfDate[2] === '00' ? 'Unknown' : partsOfDate[2] : '',
        undated: false
      }
    } else {
      this.setEmptyFullDate();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props;
    if(this.isDateUpdated(prevState)){
      this.updatedDateToHandler();
    }
    if(value !== prevProps.value){
      this.setInitialDateValue(value);
    }
  }

  isEmptyDate(){
    const { yearValue, monthValue, dateValue } = this.state;
    return yearValue === '' && monthValue === '' && dateValue === '' ? true : false;
  }

  setEmptyFullDate() {
    this.setState({
      yearValue: '',
      monthValue: '',
      dateValue: '',
      undated: false,
    });
  }

  // to update date value to handler
  updatedDateToHandler(){
    const { updateHandler } = this.props;
    if (this.isDefaultDate()) {
      // update date value Undated 
      updateHandler(`Undated`,'date');
    } else if (this.isEmptyDate()) {
      // update date to empty if 
      updateHandler(``,'date');
    } else {
      // update date value 
      updateHandler(this.getFullFormattedDate(),'date');
    }
  }

  // to get full date
  getFullFormattedDate() {
    const { yearValue, monthValue, dateValue } = this.state;
    const year = this.isValidYear(yearValue) || yearValue === 'Unknown' ? yearValue === 'Unknown' ? '0000' : yearValue : '*';
    const month = this.isValidMonth(monthValue) || monthValue === 'Unknown' ? monthValue === 'Unknown' ? '00' : this.pad(parseInt(monthValue)) : '*';
    const day = this.isValidDate(dateValue) || dateValue === 'Unknown' ? dateValue === 'Unknown' ? '00' : this.pad(parseInt(dateValue)) : '*'
    return `${year}-${month}-${day}`;
  }

  // to validate full date
  isValidFullDate(){
    const { yearValue, monthValue, dateValue } = this.state;
    return this.isValidYear(yearValue) && this.isValidMonth(monthValue) && this.isValidDate(dateValue) ? true : false;
  }

  // checks year is 0000 OR unknown or not
  isDefaultYear() {
    const { yearValue } = this.state;
    return yearValue === this.getDefaultYearValue() || yearValue === 'Unknown' ? true : false;
  }

  // checks month is 00 OR unknown or not
  isDefaultMonth() {
    const { monthValue } = this.state;
    return monthValue === this.getDefaultMonthOrDayValue() || monthValue === 'Unknown' ? true : false;
  }

  // checks day is 00 OR unknown or not
  isDefaultDay() {
    const { dateValue } = this.state;
    return dateValue === this.getDefaultMonthOrDayValue() || dateValue === 'Unknown' ? true : false;
  }

  // check date value is default 0000-00-00/Unknown
  isDefaultDate() {
    return this.isDefaultYear() && this.isDefaultMonth() && this.isDefaultDay() ? true : false;
  }

  // check date value is updated
  isDateUpdated(prevState){
    const { yearValue, monthValue, dateValue } = this.state;
    return prevState.yearValue !== yearValue || prevState.monthValue !== monthValue || prevState.dateValue !== dateValue ? true : false;
  }

  // to reset error
  resetError(){
    this.setState({
      showError: false,
      errorMessage: ''
    });
  }

  // to get default full date 0000-00-00
  getDefaultFullDate(){
    return {
      yearValue:this.getDefaultYearValue(),
      monthValue: this.getDefaultMonthOrDayValue(),
      dateValue: this.getDefaultMonthOrDayValue(),
    };
  }

  // to get full valid date
  getFullValidDate(value){
    return {
      yearValue: this.getYearFromFullDate(value),
      monthValue: this.getMonthFromFullDate(value),
      dateValue: this.getDayFromFullDate(value),
    }
  }

  // checkbox change handler
  handleCheckbox(event) {
    this.setState({
      undated: event.target.checked
    });
    if(!event.target.checked){
      this.setEmptyFullDate();
    } else {
      this.setState({
        yearValue: '0000',
        monthValue: '00',
        dateValue: '00'
      })
    }
  }

  // to reset error on valid date or default date
  setErrorMessage() {
    if(this.isValidFullDate() || this.isDefaultDate()){
      this.setState({
        showError: false, errorMessage: ''
      })
    }
  }

  // check for valid year
  isValidYear(value) {
    return parseInt(value) >= 1000 && parseInt(value) <= 2023 || value === this.getDefaultYearValue() ? true : false;
  }

  // on change event handler for year input
  onChangeYear(event) {
    if(this.isValidYear(event.target.value) || event.target.value === ""){
      this.setState({ yearValue: event.target.value, showError: false, errorMessage: '' });
    } else {
      this.setState({
        yearValue: event.target.value,
        showError: true,
        errorMessage: 'Please enter valid year.'
      })
    }
  }

  // on select event handler for year input
  onSelectYear(value) {
    this.setState({ yearValue: value, showError: false, errorMessage: '' })
  }

  // check for month validation
  isValidMonth(value) {
    return parseInt(value) >= 1 && parseInt(value) <= 12 || value === this.getDefaultMonthOrDayValue() ? true : false
  }

  // on change event handler for month input
  onChangeMonth(event) {
    if(this.isValidMonth(event.target.value) || event.target.value === ""){
      this.setState({ monthValue: event.target.value, showError: false, errorMessage: '' });
    } else {
      this.setState({
        monthValue: event.target.value,
        showError: true,
        errorMessage: 'Please enter valid Month.'
      })
    }
  }

  // on select event handler for month input
  onSelectMonth(value) {
    this.setState({monthValue: value, showError: false, errorMessage: '' })
  }

  // check for valid date
  isValidDate(value) {
    return parseInt(value) >= 1 && parseInt(value) <= 31 || value === this.getDefaultMonthOrDayValue() ? true : false;
  }

  // on change event handler for date input
  onChangeDate(event) {
    if(this.isValidDate(event.target.value) || event.target.value === ""){
      this.setState({ dateValue: event.target.value, showError: false, errorMessage: '' });
    } else {
      this.setState({
        dateValue: event.target.value,
        showError: true,
        errorMessage: 'Please enter valid Day.'
      })
    }
  }

  // on select event handler for date input
  onSelectDate(value) {
    this.setState({dateValue: value, showError: false, errorMessage: '' })
  }

  // pad 0 to single digit number
  pad(n){
    return (n < 10) ? ("0" + n) : n;
  }

  // to get range of numbers from N to N with step
  getNumbersInRange(min, max, step){
    let numbers = [];
    for(let i = min; i <= max; i += step){
       numbers.push({ id: this.pad(i).toString(), label: this.pad(i).toString() });
    }
    return numbers;
  }

  getAllYears(){
    const allYears = [{id: 'Unknown', label: 'Unknown'}]
    return [...allYears,...this.getNumbersInRange(1000,2023,1)]
  }

  getAllMonths() {
    const allMonths = [{id: 'Unknown', label: 'Unknown'}]
    return [...allMonths,...this.getNumbersInRange(1,12,1)]
  }

  getAllDays() {
    const allDays = [{id: 'Unknown', label: 'Unknown'}]
    return [...allDays,...this.getNumbersInRange(1,31,1)]
  }

  render() {
    const { undated, showError, errorMessage, yearValue, monthValue, dateValue } = this.state;
    const { classNames, field } = this.props;
    return (
      <div className={classNames} key={field}>
        {showError ? (
          <p className="error-message">
            {errorMessage}
          </p>
        ) : (
          null
        )}
        {(() => {
          if (!undated) {
            return (
              <div>
                <div className="custom-date">
                  <div className="custom-date-year">
                    <AutocompleteInputBox
                      items={this.getAllYears()}
                      currentValue={yearValue}
                      onChange={this.onChangeYear}
                      onSelect={this.onSelectYear}
                      placeholder="Year"
                    />
                  </div><span class="date-slash">/</span><div>
                    <AutocompleteInputBox
                      items={this.getAllMonths()}
                      currentValue={monthValue}
                      onChange={this.onChangeMonth}
                      onSelect={this.onSelectMonth}
                      placeholder="Month"
                    />
                  </div><span class="date-slash">/</span><div>
                    <AutocompleteInputBox
                      items={this.getAllDays()}
                      currentValue={dateValue}
                      onChange={this.onChangeDate}
                      onSelect={this.onSelectDate}
                      placeholder="Day"
                    />
                  </div>
                </div>
              </div>
            )
          }
        })()}
        <div>
          <label for="undated" className="custom-check">Undated
            <input type="checkbox" id="undated" checked={undated} onChange={this.handleCheckbox}/>
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
    );
  }
}

export default CustomDateField;