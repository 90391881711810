import React, { Component } from 'react';

import lockGray from "../images/lock.png";
import unlockGray from "../images/unlocked.png";

const zoomLockReducer = (state = {}, action) => {
    // return the updated data with the original state variables
    if (action.type === 'mirador/SET_CANVAS') {
        if (state[action.windowId]) {
            state[action.windowId]['allowSetCanvasZoom'] = true;
        } else {
            state[action.windowId] = {
                "allowSetCanvasZoom": true,
                "allowUpdateViewport": false,
                "allowUpdateViewWhileLock": false,
                "allowMaximizeWindow": false,
                "allowMinimizeWindow": false
            }
        }
        return {
            ...state
        };
    }
    if (action.type === 'mirador/UPDATE_VIEWPORT') {
        if (state[action.windowId]) {
            if (state[action.windowId]['allowSetCanvasZoom']) {
                state[action.windowId]['allowUpdateViewport'] = true;
            }
            else {
                state[action.windowId]['allowUpdateViewWhileLock'] = true;
            }
        } else {
            state[action.windowId] = {
                "allowSetCanvasZoom": false,
                "allowUpdateViewport": false,
                "allowUpdateViewWhileLock": true,
                "allowMaximizeWindow": false,
                "allowMinimizeWindow": false
            }
        }
        return {
            ...state
        };
    }
    if (action.type === 'mirador/UPDATE_ZOOM_LOCK') {
        if (state[action.windowId]) {
            state[action.windowId]['zoomLockData'] = action.payload;
            return {
                ...state
            };
        }
    }
    if (action.type === 'mirador/HANDLE_ZOOM_LOCK') {
        if (state[action.windowId]) {
            const payload = action.payload
            if (payload.allowSetCanvasZoom != undefined) {
                state[action.windowId]['allowSetCanvasZoom'] = payload.allowSetCanvasZoom;
            }
            if (payload.allowUpdateViewport != undefined) {
                state[action.windowId]['allowUpdateViewport'] = payload.allowUpdateViewport;
            }
            if (payload.allowUpdateViewWhileLock != undefined) {
                state[action.windowId]['allowUpdateViewWhileLock'] = payload.allowUpdateViewWhileLock;
            }
            if (payload.allowMaximizeWindow != undefined) {
                state[action.windowId]['allowMaximizeWindow'] = payload.allowMaximizeWindow;
            }
            if (payload.allowMinimizeWindow != undefined) {
                state[action.windowId]['allowMinimizeWindow'] = payload.allowMinimizeWindow;
            }
            return {
                ...state
            };
        }
    }
    if (action.type === 'mirador/MAXIMIZE_WINDOW' || action.type === 'mirador/MINIMIZE_WINDOW') {
        if (state[action.windowId]) {
            state[action.windowId]['allowMaximizeWindow'] = true; // comment for multiple window
            return {
                ...state
            };
        }
    }
    return state;
};

const mapDispatchToProps = (dispatch) => ({
    updateZoomLockData: (windowId, payload) => dispatch({ type: 'mirador/UPDATE_ZOOM_LOCK', windowId: windowId, payload: payload }),
    handleZoomLockData: (windowId, payload) => dispatch({ type: 'mirador/HANDLE_ZOOM_LOCK', windowId: windowId, payload: payload }),
});

const mapStateToProps = (state) => {
    return {
        state: state
    }
};

class DocumentZoomLockPlugin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLocked: false
        };
        this.setZoomButton();
    }

    componentDidUpdate(prevProps) {
        const { targetProps, state } = this.props;
        const windowId = targetProps.windowId;
        if (state.zoomLock && state.zoomLock[windowId]) {
            const viewerObject = state.zoomLock[windowId].zoomLockData;
            if (state.zoomLock[windowId].allowUpdateViewport && state.zoomLock[windowId].allowSetCanvasZoom) {
                this.props.handleZoomLockData(windowId, {
                    allowUpdateViewport: false,
                    allowSetCanvasZoom: false
                });
                if (viewerObject && viewerObject.zoomLock) {
                    setTimeout(() => {
                        this.props.targetProps.updateViewport(windowId,viewerObject.viewer);
                    }, 0);
                }
            }
            if (state.zoomLock[windowId].allowUpdateViewWhileLock && !state.zoomLock[windowId].allowMaximizeWindow && !state.zoomLock[windowId].allowMinimizeWindow) {
                this.props.handleZoomLockData(windowId, {
                    allowUpdateViewWhileLock: false
                });
                if (viewerObject && viewerObject.zoomLock) {
                    this.enableDisableZoom(windowId, true);
                }
            }
            if (state.zoomLock[windowId].allowMaximizeWindow) {
                this.props.handleZoomLockData(windowId, {
                    allowMaximizeWindow: false,
                });
                if (viewerObject && viewerObject.zoomLock) {
                    setTimeout(() => {
                        this.props.targetProps.updateViewport(windowId,viewerObject.viewer);
                    }, 0);
                }
            }
        }
    }

    setZoomButton() {
        const { targetProps, state } = this.props;
        const windowId = targetProps.windowId;
        if (state.zoomLock && state.zoomLock[windowId] && state.zoomLock[windowId].zoomLockData) {
            const zoomLockData = state.zoomLock[windowId].zoomLockData;
            if (zoomLockData.zoomLock) {
                this.state = {
                    isLocked: true
                };
            }
        }
    }

    lockUnlockZoom(isLocked) {
        const { targetProps } = this.props;
        const windowId = targetProps.windowId;
        this.enableDisableZoom(windowId, isLocked);
    }

    enableDisableZoom(windowId, value) {
        this.setState({
            isLocked: value,
        });
        this.props.updateZoomLockData(windowId, {
            zoomLock: value,
            viewer: this.props.targetProps.viewer
        })
    }

    /**
     * @description render view
     */
    render() {
        const { targetProps } = this.props;
        const { isLocked } = this.state;
        let imageSrc = unlockGray;
        let lockUnlockData = true;
        if (isLocked) {
            imageSrc = lockGray;
            lockUnlockData = false;
        }
        return (
            <React.Fragment>
                <div className="mr-2 zoom-lock-btn" title="Preserve Zoom" aria-label="Preserve Zoom" tabIndex="0">
                    <img src={imageSrc} onClick={() => { this.lockUnlockZoom(lockUnlockData) }} />
                </div>
                <this.props.TargetComponent {...targetProps} />
            </React.Fragment>
        );
    }
}

export default {
    target: 'ZoomControls',
    mode: 'wrap',
    name: 'DocumentZoomLockPlugin',
    component: DocumentZoomLockPlugin,
    mapStateToProps,
    mapDispatchToProps,
    reducers: {
        zoomLock: zoomLockReducer,
    },
};