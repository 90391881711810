import React, { Component } from 'react';
import Modal from "react-bootstrap-modal";
import Mirador from "../lib/mirador/mirador.min.js";
import Collapse, { Panel } from 'rc-collapse';
import uuidV4 from "uuid/v4";

import { getLatestWindowId } from "./selector/windows";
import SearchPagination from "./components/pagination";
import { getDocuments, getFilters, getFiltersByType, getManifestData } from "../services/documentService";
import { CONSTANTS } from "../lib/constants";
import { createRequestObjectForDocuments, createRequestObjectForFilters, isMobileView, validateUser, getViewConfigs } from "../lib/utils";
import CustomCollapse from "./components/collapse";
import CustomDateField from "./components/CustomDateField";

import searchIconGray from "../images/search-icon-gray.png";
import noDataImage from "../images/result_not_found.png";
import closeIcon from "../images/close-icon.png";
import closeIconGray from "../images/close-icon-gray.png";
import filterIcon from "../images/filter-icon.png";
import backArrowIcon from "../images/arrow-left-filled.png";
import infoSvgIcon from "../images/icon/info.svg";

const { getFormattedDate, getFormattedDateForTooltip } = require("../lib/dateUtils");

const actions = Mirador.actions;

const mapDispatchToProps = (dispatch) => ({
    closeSearchDialog: () => dispatch({ type: 'mirador/CLOSE_WINDOW_DIALOG' }),
    openDocument: (manifestId) => dispatch(actions.addWindow({ manifestId: manifestId })),
    openSidePanel: (windowId, payload) => dispatch(actions.addCompanionWindow(windowId, payload)),
    updateDocumentType: (windowId, type) => dispatch(actions.setWindowViewType(windowId,type)),
});

const mapStateToProps = (state) => {
    return {
        show: state.windowDialogs ? state.windowDialogs.openDialog === 'search' : false,
        newWindowId: getLatestWindowId(state),
        workspace: state.workspace
    };
};

class DocumentSearchDialogPlugin extends Component {
    constructor(props) {
        super(props);

        this.filtersDialogRef = null;

        this.state = {
            documents: [],
            filtersData: {},
            filterObject: {
                page: CONSTANTS.DEFAULT_PAGE,
                perPage: 5,
                term: '',
                creator: '',
                contributor: '',
                date: '',
                collection: '',
                type: '',
                subject: '',
                category: '',
                call_number: '',
                coverage: '',
                tag: '',
                moreFilters: {
                    filterTerm: '',
                    page: CONSTANTS.DEFAULT_PAGE,
                    perPage: 20,
                    type: ''
                }
            },
            searchObject: {
                fieldToSort: 'label',
                order: 'asc',
                page: CONSTANTS.DEFAULT_PAGE,
                perPage: 20,
                searchOption: 'metadata',
                searchType: 'all_words'
            },
            totalFilterItems: CONSTANTS.DEFAULT_NUMBER_OF_ITEMS,
            totalDocumentsCount: CONSTANTS.DEFAULT_NUMBER_OF_ITEMS,
            totalItems: CONSTANTS.DEFAULT_NUMBER_OF_ITEMS,
            hideMobileFilters: isMobileView(),
            hideFilterDialog: true,
            displayMessageModal: false
        };

        this.onPageChange = this.onPageChange.bind(this);
        this.onChangeSearchType = this.onChangeSearchType.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.show !== this.props.show && this.props.show || prevState.searchObject.searchType !== this.state.searchObject.searchType) {
            const { filterObject, searchObject } = this.state;

            this.getDocumentList(createRequestObjectForDocuments(searchObject, filterObject, true));

            this.getFiltersData(createRequestObjectForDocuments(searchObject, filterObject, false));
        }
    }

    addNewDocument(manifestId, access) {
        const { openDocument, closeSearchDialog } = this.props;
        if(access === 'registered'){
            validateUser().then((res) => {
                if(res){
                    openDocument(manifestId);
                    if(!isMobileView()){
                        this.setDocumentType(manifestId);
                    }
                    closeSearchDialog();
                } else {
                    localStorage.removeItem('nat');
                    this.setState({
                        displayMessageModal: true
                    })
                }
            }) 
        } else {
            openDocument(manifestId);
            if(!isMobileView()){
                this.setDocumentType(manifestId);
            }
            closeSearchDialog();
        }
    }

    closeMessageModal() {
        this.setState({
            displayMessageModal: false
        })
    }

    redirectToLink(redirectLink) {
        window.top.location.href = `${ENV.USER_BASE_URL}${redirectLink}`;
    }

    handleFilterSelect(data, type) {
        try {
            const { filterObject, searchObject } = this.state;

            const prevFilterObject = { ...filterObject };
            prevFilterObject[type] = data;

            const prevSearchObject = { ...searchObject };
            prevSearchObject.page = 1;

            this.setState({
                filterObject: prevFilterObject,
                searchObject: prevSearchObject
            })

            this.hideFiltersDialog(prevFilterObject);

            this.getDocumentList(createRequestObjectForDocuments(searchObject, prevFilterObject, true));

            this.getFiltersData(createRequestObjectForDocuments(searchObject, prevFilterObject, false));
        } catch (error) {
            console.log(error);
        }
    }

    removeFilter(type) {
        try {
            const { filterObject, searchObject } = this.state;

            const prevFilterObject = { ...filterObject };
            prevFilterObject[type] = '';

            this.setState({
                filterObject: prevFilterObject
            })
            this.getDocumentList(createRequestObjectForDocuments(searchObject, prevFilterObject, true));

            this.getFiltersData(createRequestObjectForDocuments(searchObject, prevFilterObject, false));
        } catch (error) {
            console.log(error);
        }
    }

    onPageChange(page) {
        try {
            const { filterObject, searchObject } = this.state;

            const prevSearchObject = { ...searchObject };
            prevSearchObject.page = page;

            this.setState({
                searchObject: prevSearchObject
            })

            this.getDocumentList(createRequestObjectForDocuments(prevSearchObject, filterObject, true));
        } catch (error) {
            console.log(error);
        }
    }

    getFiltersData(requestObject) {
        getFilters(requestObject).then((response) => {
            this.setState({
                filtersData: response.data.data
            });
        }).catch((error) => {
            console.log(error);
        })
    }

    getMoreFiltersByType(requestObject, type) {
        getFiltersByType(requestObject, type).then((response) => {
            this.setState({
                moreFiltersData: response.data.data[type],
                totalFilterItems: response.data.count
            })
        }).catch((error) => {
            console.log(error);
        })
    }

    getDocumentList(requestObject) {
        getDocuments(requestObject).then((response) => {
            this.setState({
                totalItems: response.data.count,
                documents: response.data.data,
                totalDocumentsCount: response.data.totalDocumentsCount,
            })
        }).catch((error) => {
            console.log(error);
        })
    }

    isDateFilterActive() {
      const { filterObject } = this.state;
      return filterObject['date'] && filterObject['date'] !== '' ? true : false
    }

    filtersExpandableHTML() {
        try {
            const { filtersData, filterObject } = this.state;
            let collapsibles = [];

            if (filtersData.creator && filtersData.creator.length > 0) {
                collapsibles.push(
                    <CustomCollapse
                        label='Creator'
                        type='creator'
                        panelData={filtersData.creator}
                        comparator={filterObject}
                        handleFilterSelect={(key, type) => { this.handleFilterSelect(key, type) }}
                        removeFilter={(type) => { this.removeFilter(type) }}
                        showMoreFilters={(type) => { this.showMoreFilters(type) }}
                    />
                );
            }

            collapsibles.push(
                this.filtersDialogHTML()
            )

            if (filtersData.contributor && filtersData.contributor.length > 0) {
                collapsibles.push(
                    <CustomCollapse
                        label='Contributor'
                        type='contributor'
                        panelData={filtersData.contributor}
                        comparator={filterObject}
                        handleFilterSelect={(key, type) => { this.handleFilterSelect(key, type) }}
                        removeFilter={(type) => { this.removeFilter(type) }}
                        showMoreFilters={(type) => { this.showMoreFilters(type) }}
                    />
                );
            }

            collapsibles.push(
                <Collapse accordion={false} defaultActiveKey={this.isDateFilterActive() ? [`Date`] : ['']} id="date">
                    <Panel header={`Date`} key={`Date`}>
                        <CustomDateField
                          updateHandler={(key, type) => { this.handleFilterSelect(key, type) }}
                          value={filterObject.date}
                          customClass="form-control"
                        />
                    </Panel>
                </Collapse>
            );

            if (filtersData.collection && filtersData.collection.length > 0) {
                collapsibles.push(
                    <CustomCollapse
                        label='Collection'
                        type='collection'
                        panelData={filtersData.collection}
                        comparator={filterObject}
                        handleFilterSelect={(key, type) => { this.handleFilterSelect(key, type) }}
                        removeFilter={(type) => { this.removeFilter(type) }}
                        showMoreFilters={(type) => { this.showMoreFilters(type) }}
                    />
                );
            }

            if (filtersData.type && filtersData.type.length > 0) {
                collapsibles.push(
                    <CustomCollapse
                        label='Type'
                        type='type'
                        panelData={filtersData.type}
                        comparator={filterObject}
                        handleFilterSelect={(key, type) => { this.handleFilterSelect(key, type) }}
                        removeFilter={(type) => { this.removeFilter(type) }}
                        showMoreFilters={(type) => { this.showMoreFilters(type) }}
                    />
                );
            }

            if (filtersData.subject && filtersData.subject.length > 0) {
                collapsibles.push(
                    <CustomCollapse
                        label='Subject'
                        type='subject'
                        panelData={filtersData.subject}
                        comparator={filterObject}
                        handleFilterSelect={(key, type) => { this.handleFilterSelect(key, type) }}
                        removeFilter={(type) => { this.removeFilter(type) }}
                        showMoreFilters={(type) => { this.showMoreFilters(type) }}
                    />
                );
            }

            if (filtersData.category && filtersData.category.length > 0) {
              collapsibles.push(
                  <CustomCollapse
                      label='Category'
                      type='category'
                      panelData={filtersData.category}
                      comparator={filterObject}
                      handleFilterSelect={(key, type) => { this.handleFilterSelect(key, type) }}
                      removeFilter={(type) => { this.removeFilter(type) }}
                      showMoreFilters={(type) => { this.showMoreFilters(type) }}
                  />
              );
            }

            if (filtersData.call_number && filtersData.call_number.length > 0) {
              collapsibles.push(
                  <CustomCollapse
                      label='Call number'
                      type='call_number'
                      panelData={filtersData.call_number}
                      comparator={filterObject}
                      handleFilterSelect={(key, type) => { this.handleFilterSelect(key, type) }}
                      removeFilter={(type) => { this.removeFilter(type) }}
                      showMoreFilters={(type) => { this.showMoreFilters(type) }}
                  />
              );
            }

            if (filtersData.coverage && filtersData.coverage.length > 0) {
              collapsibles.push(
                  <CustomCollapse
                      label='Coverage'
                      type='coverage'
                      panelData={filtersData.coverage}
                      comparator={filterObject}
                      handleFilterSelect={(key, type) => { this.handleFilterSelect(key, type) }}
                      removeFilter={(type) => { this.removeFilter(type) }}
                      showMoreFilters={(type) => { this.showMoreFilters(type) }}
                  />
              );
            }

            if (filtersData.tags && filtersData.tags.length > 0) {
              collapsibles.push(
                  <CustomCollapse
                      label='Tag'
                      type='tag'
                      panelData={filtersData.tags}
                      comparator={filterObject}
                      handleFilterSelect={(key, type) => { this.handleFilterSelect(key, type) }}
                      removeFilter={(type) => { this.removeFilter(type) }}
                      showMoreFilters={(type) => { this.showMoreFilters(type) }}
                  />
              );
            }

            return collapsibles.length === 0 ? null : collapsibles;

        } catch (error) {
            console.log(error);
        }
    }

    onChangeSearchType({ target: { value } }) {
      this.setState(prevState => {
        let searchObject = Object.assign({}, prevState.searchObject);
        searchObject.searchType = value;
        return { searchObject };
      });
    }

    // renders advanced search options
    getAdvancedSearchFilterOptions() {
      const { searchObject: { searchType } } = this.state;
      try {
        return (
          <div class="advanced-search">
            <div>
              <select
                class="custom-select mt-3"
                value={searchType}
                onChange={this.onChangeSearchType}
              >
                <option value="all_words">All Words</option>
                <option value="any_word">Any Word</option>
                <option value="exact_phrase">Exact Phrase</option>
                <option value="wildcard">Wildcard</option>
              </select>
            </div>
          </div>
        )
      } catch (error) {
          console.log(error);
      }
    }

    sortingPanelHTML() {
        try {
            const { filterObject, hideMobileFilters } = this.state;

            if (hideMobileFilters) return null;

            return (
                <div className="col-sm-4 col-xl-3 filter-mobile-view">
                    <div className="filter-wrap">
                        <div className="side-search">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search By Keyword" value={filterObject.term} onChange={() => { this.handleFilterSelect(event.target.value, 'term') }} />
                                <div className="input-group-append">
                                    <button className="btn-search" type="button">
                                        <img src={searchIconGray} alt="#" />
                                    </button>
                                </div>
                            </div>
                            {this.getAdvancedSearchFilterOptions()}
                        </div>
                        {this.filtersExpandableHTML()}
                    </div>
                    {isMobileView() && (
                        <React.Fragment>
                            <div className="more-item-close-mobile" onClick={() => { this.hideMobileFilters() }}>
                                <img src={closeIconGray} alt="" />
                            </div>
                            <button className="btn btn-danger w-100 applyMobile" onClick={() => { this.hideMobileFilters() }}>APPLY</button>
                        </React.Fragment>
                    )}
                </div>
            );
        } catch (error) {
            console.log(error);
        }
    }

    getTooltipLabel(data) {
        switch (data) {
            case 'creator':
                return 'Creator';
            case 'contributor':
                return 'Contributor';
            case 'collection':
                return 'Collection';
            case 'type':
                return 'Type';
            case 'term':
                return 'Search Term';
            case 'subject':
                return 'Subject';
            case 'date':
                return 'Date';
            case 'category':
                return 'Category';
            case 'call_number':
                return 'Call number';
            case 'coverage':
                return 'Coverage';
            case 'tag':
                return 'Tag';
            default:
                break;
        }
    }

    getTooltipValue(label,value) {
      if(label.toLowerCase() === 'date'){
        if(getFormattedDateForTooltip(value) === ""){
          return ""
        } else {
          return getFormattedDateForTooltip(value)
        }
      }
      return value;
    }

    documentHeadToolTipHTML() {
        try {
            const { filterObject } = this.state;
            const ignoreTerms = ['page', 'perPage', 'moreFilters'];
            return (
                <div className="search-result-head">
                    <div className="row m-0 justify-content-between">
                        <div className="filter-on">
                            {Object.keys(filterObject).map(data => {
                                if (!ignoreTerms.includes(data) && filterObject[data] && this.getTooltipValue(this.getTooltipLabel(data),filterObject[data]) !== "") {
                                    return (
                                        <label className="custom-tooltip" key={`'tooltip'-${uuidV4()}`}>
                                            <span className="lbl-span">
                                                {this.getTooltipValue(this.getTooltipLabel(data),filterObject[data])}
                                                <img onClick={() => { this.removeFilter(data) }} src={closeIcon} alt="#" />
                                            </span>
                                            <span className="custom-tooltiptext">
                                                {`${this.getTooltipLabel(data)}: ${this.getTooltipValue(this.getTooltipLabel(data),filterObject[data])}`}
                                            </span>
                                        </label>
                                    );
                                }
                            })}
                        </div>
                        {isMobileView() && (
                            <div className="sort-sec" onClick={() => { this.showMobileFilters() }}>
                                <img src={filterIcon} height="25" alt="" />
                            </div>
                        )}
                    </div>
                </div>
            );
        } catch (error) {
            console.log(error);
        }
    }

    documentListHTML() {
        try {
            const { documents } = this.state;
            return (
                <div className="search-result-details">
                    <div className="flex-container">
                        {documents.length === 0 && (
                            <div className="no-data">
                                <img className="no-data-img" src={noDataImage} />
                                <p>We've searched more than 5000 documents,</p>
                                <p>but regret that no results were found.</p>
                            </div>)
                        }
                        {documents.map(data => {
                            return (
                                <div className="flex-item mb-3 detail-view" key={data['_id']} onClick={() => { this.addNewDocument(data['@id'], data.access) }}>
                                    <div className="result-box" style={{ backgroundImage: `url(${data.thumbnail.imageUrl})` }}>
                                    </div>
                                    <div className="result-box-details">
                                        <h5>{data.label}</h5>
                                        <p><span>Creator: </span>{data.creator[0] || 'No creator'}</p>
                                        <p><span>Contributor: </span>{data.contributor[0] || 'No contributor'}</p>
                                        <p><span>Date: </span>{getFormattedDate(data.date) || 'No date'}</p>
                                        <p><span>Description: </span>{data.description[0] || 'No description'}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        } catch (error) {
            console.log(error);
        }
    }

    showMobileFilters() {
        try {
            this.setState({
                hideMobileFilters: false
            })
        } catch (error) {
            console.log(error);
        }
    }

    hideMobileFilters() {
        try {
            this.setState({
                hideMobileFilters: true
            })
        } catch (error) {
            console.log(error);
        }
    }

    showMoreFilters(type) {
        try {
            const { filterObject } = this.state;
            const prevFilterObject = { ...filterObject };
            prevFilterObject.moreFilters.type = type;

            this.setState({
                filterObject: prevFilterObject
            });
            this.showFiltersDialog();
            this.getMoreFiltersByType(createRequestObjectForFilters(filterObject), type);
        } catch (error) {
            console.log(error);
        }
    }

    searchFilters(term, page, type) {
        try {
            const { filterObject } = this.state;
            const prevFilterObject = { ...filterObject };
            prevFilterObject.moreFilters.filterTerm = term;
            prevFilterObject.moreFilters.page = page;

            this.setState({
                filterObject: prevFilterObject
            });

            this.getMoreFiltersByType(createRequestObjectForFilters(prevFilterObject), type);
        } catch (error) {
            console.log(error);
        }
    }

    onInputChange(term, page = 1) {
        try {
            const { filterObject } = this.state;
            const prevFilterObject = { ...filterObject };
            prevFilterObject.moreFilters.filterTerm = term;
            prevFilterObject.moreFilters.page = page;

            this.setState({
                filterObject: prevFilterObject
            });

        } catch (error) {
            console.log(error);
        }
    }

    handleSubmit(type) {
        try {
            event.preventDefault();
            this.getMoreFiltersByType(createRequestObjectForFilters(this.state.filterObject), type);
        } catch (error) {
            console.log(error);
        }
    }

    renderSearchInput() {
        const { filterObject } = this.state;
        return (
            <div className="row">
                <div className="col-sm-8">
                    <form onSubmit={() => this.handleSubmit(filterObject.moreFilters.type)}>
                        <div className="more-item-header">
                            {isMobileView() && (
                                <div className="back-arrow-mobile" onClick={() => { this.hideFiltersDialog(filterObject) }}>
                                    <img src={backArrowIcon} alt="" />
                                </div>
                            )}
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Filter By Keywords" value={filterObject.moreFilters.filterTerm}
                                    onChange={() => { this.onInputChange(event.target.value) }} />
                                <div className="input-group-append">
                                    <button className="btn-search" type="submit">
                                        <img src={searchIconGray} alt="#" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div> 
        )
    }

    showFiltersDialog() {
        try {
            this.setState({
                hideFilterDialog: false
            })
        } catch (error) {
            console.log(error);
        }
    }

    hideFiltersDialog(prevFilterObject) {
        try {
            this.setState({
                hideFilterDialog: true
            })
            this.resetFilterSearch(prevFilterObject);
        } catch (error) {
            console.log(error);
        }
    }

    resetFilterSearch(prevFilterObject) {
        try {
            prevFilterObject.moreFilters = {
                filterTerm: '',
                page: CONSTANTS.DEFAULT_PAGE,
                perPage: 20,
                type: ''
            };

            this.setState({
                filterObject: prevFilterObject
            })
        } catch (error) {
            console.log(error);
        }
    }

    filtersDialogHTML() {
        try {
            const { moreFiltersData, filterObject, totalFilterItems, hideFilterDialog } = this.state;

            if (hideFilterDialog) return;

            return (
                <div className="more-item-wrap opacity-1">
                    <div className="container-fluid">
                        {!isMobileView() && (
                            <div className="more-item-close" onClick={() => { this.hideFiltersDialog(filterObject) }}>
                                <img src={closeIconGray} alt="" />
                            </div>
                        )}
                        {this.renderSearchInput()}

                        <div className="more-search-result-mobile">
                            <div className="row">
                                {moreFiltersData.length === 0 && (
                                    <div className="no-data">
                                        <p>No results found</p>
                                    </div>)
                                }
                                {moreFiltersData && moreFiltersData.map((data) => {
                                    return (
                                        <div className="col-sm-6" key={uuidV4()} onClick={() => {
                                            this.handleFilterSelect(data.key, filterObject.moreFilters.type)
                                        }}>
                                            <div className="more-search-result">
                                                <p>{data.key}<span>{data.doc_count}</span></p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <SearchPagination
                            activePage={filterObject.moreFilters.page}
                            itemsPerPage={filterObject.moreFilters.perPage}
                            totalItems={totalFilterItems}
                            hideTotal={true}
                            pageChange={(page) => { this.searchFilters(filterObject.moreFilters.filterTerm, page, filterObject.moreFilters.type) }}
                            pageRangeDisplayed={isMobileView() ? 5 : 10}
                        ></SearchPagination>
                    </div>
                </div>
            );
        } catch (error) {
            console.log(error);
        }
    }

    resetSearch() {
        try {
            this.setState({
                filtersData: {},
                filterObject: {
                    page: CONSTANTS.DEFAULT_PAGE,
                    perPage: 5,
                    term: '',
                    creator: '',
                    contributor: '',
                    date: '',
                    collection: '',
                    type: '',
                    subject: '',
                    moreFilters: {
                        filterTerm: '',
                        page: CONSTANTS.DEFAULT_PAGE,
                        perPage: 20,
                        type: ''
                    }
                },
                searchObject: {
                    fieldToSort: 'label',
                    order: 'asc',
                    page: CONSTANTS.DEFAULT_PAGE,
                    perPage: 20,
                }
            })
        } catch (error) {
            console.log(error);
        }
    }

    showValidationModal() {
        const { displayMessageModal } = this.state;
        return (
            <Modal 
                className="validation-modal"
                show={displayMessageModal} 
                container={document.querySelector(`.mirador-viewer`)}
                aria-labelledby="messageModal"
                onHide={()=>{this.closeMessageModal()}}
            >
              <Modal.Body>
              <img
                    src={infoSvgIcon}
                    alt="#"
                />
                <button type="button" className='close' onClick={() => { this.closeMessageModal(); }}>x</button>
                  <h4>Log-in required!</h4>
                  <p>You must be a registered user and logged in to view this item.</p>
                <button type="button" className="btn btn-n-secondary btn-small mr-3" onClick={() => { this.redirectToLink('/sign-in')}}>SIGN IN</button>
                <button type="button" className="btn btn-n-primary btn-small" onClick={() => { this.redirectToLink('/sign-up')}}>SIGN UP</button>
                  </Modal.Body>
            </Modal>
        );
      }

    /**
     * @description render view
     */
    render() {
        const { show, closeSearchDialog } = this.props;
        const { totalItems, totalDocumentsCount, searchObject } = this.state;

        if (!show) return (<React.Fragment />);
        return (
            <React.Fragment>
                <Modal
                    container={document.querySelector(`.mirador-viewer`)}
                    show={show}
                    aria-labelledby="searchModal"
                >
                    <Modal.Header>
                        <h4 className="mt-2">Search Documents</h4>
                        <button type="button" className='close' onClick={() => { closeSearchDialog(); this.resetSearch() }}>x</button>
                    </Modal.Header>
                    <Modal.Body className="row no-gutters-right">
                        {this.sortingPanelHTML()}
                        <div className="col-sm-8 col-xl-9">
                            {this.documentHeadToolTipHTML()}
                            {this.documentListHTML()}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <SearchPagination
                            activePage={searchObject.page}
                            itemsPerPage={searchObject.perPage}
                            totalItems={totalItems}
                            totalDocumentsCount={totalDocumentsCount}
                            pageChange={this.onPageChange}
                            pageRangeDisplayed={5}
                        ></SearchPagination>
                    </Modal.Footer>
                </Modal>
                {this.showValidationModal()}
            </React.Fragment>
        );
    }

    setDocumentType(manifestId) {
        getManifestData(manifestId).then((response) => {
            if (response.status == 200) {
                let manifestData = response.data;
                const { updateDocumentType, newWindowId } = this.props;
                let view = getViewConfigs(manifestData.behavior);
                updateDocumentType(newWindowId, view);
            }
        }).catch((error) => {
            console.log(error);
        })
    }
}

export default {
    target: 'BackgroundPluginArea',
    mode: 'add',
    name: 'DocumentSearchDialogPlugin',
    component: DocumentSearchDialogPlugin,
    mapStateToProps,
    mapDispatchToProps,
};