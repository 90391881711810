import React from "react";
import ReactAutocomplete from "react-autocomplete"

class AutocompleteInputBox extends React.Component {
  constructor(props) {
    super(props);
  }

  // override css for autocomplete input box
  getMenuStyle() {
    return {
      backgroundColor :"#FFF",
      zIndex: 10,
      borderRadius: '3px',
      boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
      background: 'rgba(255, 255, 255, 0.9)',
      fontSize: '90%',
      position: 'fixed',
      overflow: 'auto',
      maxHeight: '50%',
    }
  }

  // to render auto complete list item
  renderItem(item, isHighlighted) {
    return(
      <div
        key={item.id}
        className="custom-date-list"
        style={{ background: isHighlighted ? 'lightgray' : 'white' }}
      >{item.label}
      </div>
    )
  }

  getInputProps() {
    const { placeholder } = this.props;
    return { placeholder };
  }

  render() {
    const { items, currentValue, onChange, onSelect } = this.props;
    const menuStyle = this.getMenuStyle();
    const inputProps = this.getInputProps();
    return (
      <ReactAutocomplete
        menuStyle={menuStyle}
        items={items}
        shouldItemRender={(item, value) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1}
        getItemValue={item => item.label}
        renderItem={(item, isHighlighted) => this.renderItem(item, isHighlighted)}
        value={currentValue}
        onChange={value => onChange(value)}
        onSelect={value => onSelect(value)}
        inputProps={inputProps}
      />
    );
  }
}

export default AutocompleteInputBox;