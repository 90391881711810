import React, { Component } from "react";
import PropTypes from 'prop-types';
import './Slider.scss';

class Slider extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { min, max, value, changeHandler } = this.props;
    return (
      <input type="range" min={min} max={max} value={value} onChange={changeHandler}></input>
    );
  }
}

Slider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  changeHandler: PropTypes.func.isRequired,
}

Slider.defaultProps = {
  min: 0,
  max: 100,
  value: 50
};

export default Slider