import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from './components/Button';
import Mirador from "../lib/mirador/mirador.min";

// Constants
import { CONSTANTS } from "../lib/constants";

// Icons
import closeImageToolsIcon from '../images/icon/close-image-tools.svg';
import tuneImageToolsIcon from '../images/icon/tune-image-tools.svg';
import brightnessIcon from '../images/icon/brightness-icon.svg'
import contrastIcon from '../images/icon/contrast-icon.svg';
import saturationIcon from '../images/icon/saturation-icon.svg';
import grayScaleIcon from '../images/icon/grey-scale-icon.svg';
import invertImageIcon from '../images/icon/invert-image-icon.svg';
import revertImageIcon from '../images/icon/revert-image-icon.svg';

// Mirador actions and selectors
const actions = Mirador.actions;
const selectors = Mirador.selectors;

// Tools
import ImageRotation from './imageToolsPlugin/containers/imageRotation'
import ImageFlip from './imageToolsPlugin/containers/imageFlip';
import ImageTool from './imageToolsPlugin/containers/imageTool';

// Utils
const { getFilterConfig } = require('../lib/utils');

const mapDispatchToProps = (dispatch) => ({
  updateWindow: (windowId, payload) => dispatch(actions.updateWindow(windowId, payload)),
})

const mapStateToProps = (state, { windowId }) => {
  return {
    containerId: selectors.getContainerId(state),
    enabled: selectors.getWindowConfig(state, { windowId }).imageToolsEnabled || false,
    open: selectors.getWindowConfig(state, { windowId }).imageToolsOpen || false,
    appliedFilters: getFilterConfig(state, windowId) || {}
  }
};

class ImageToolsPlugin extends Component {
  constructor(props) {
    super(props);
    this.toggleState = this.toggleState.bind(this);
    this.toggleRotate = this.toggleRotate.bind(this);
    this.toggleFlip = this.toggleFlip.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.state = {
      filterConfigs: {
        flip : false,
        brightness : 100,
        contrast : 100,
        saturate : 100,
        grayscale : 0,
        invert : 0,
        rotation : 0,
      }
    }
  }

  componentDidMount() {
    const { viewer } = this.props;
    if (viewer) this.applyFilters();
  }

  componentDidUpdate(prevProps, prevState) {
    const { appliedFilters, viewer } = this.props;
    const { filterConfigs } = this.state;
    // Check for already applied filters
    if (JSON.stringify(appliedFilters) !== JSON.stringify(prevProps.appliedFilters)) {
      this.setState({ filterConfigs: appliedFilters });
    }
    // Check filter updated in state then apply it
    if(viewer && JSON.stringify(filterConfigs) !== JSON.stringify(prevState.filterConfigs)){
      this.applyFilters();
    }
  }

  /**
   * @description To collapse or expand image tools
   */
  toggleState() {
    const { open, updateWindow, windowId } = this.props;
    updateWindow(windowId, { imageToolsOpen: !open });
  }

  /**
   * @description To rotate image
   */
  toggleRotate(value) {
    const { filterConfigs : { flip = false, rotation = 0 }} = this.state;
    const offset = flip ? -1 * value : value;
    this.setState(prevState => {
      let filterConfigs = Object.assign({}, prevState.filterConfigs);
      filterConfigs.rotation = (rotation + offset) % 360;
      return { filterConfigs };
    })
  }

  /**
   * @description to flip image
   */
  toggleFlip() {
    this.setState(prevState => {
      let filterConfigs = Object.assign({}, prevState.filterConfigs);
      filterConfigs.flip = !filterConfigs.flip;
      return { filterConfigs };
    });
  }

  /**
   * @description handleChange for image tools
   */
  handleChange(param) {
    return (value) => {
      this.setState(prevState => {
        let filterConfigs = Object.assign({}, prevState.filterConfigs);
        filterConfigs[param] = value;
        return { filterConfigs };
      });
    };
  }

  /**
   * @description To apply filters in image canvas
   */
  applyFilters() {
    const { viewer } = this.props;
    const {
      filterConfigs: {
        flip = false,
        brightness = 100,
        contrast = 100,
        saturate = 100,
        grayscale = 0,
        invert = 0,
        rotation = 0,
      },
    } = this.state;

    if (!viewer.canvas) return;

    const controlledFilters = ['brightness', 'contrast', 'saturate', 'grayscale', 'invert'];

    const currentFilters = viewer.canvas.style.filter.split(' ');
    const newFilters = currentFilters.filter(
      (filter) => !controlledFilters.some((type) => filter.includes(type)),
    );

    newFilters.push(`brightness(${brightness}%)`);
    newFilters.push(`contrast(${contrast}%)`);
    newFilters.push(`saturate(${saturate}%)`);
    newFilters.push(`grayscale(${grayscale}%)`);
    newFilters.push(`invert(${invert}%)`);
    viewer.canvas.style.filter = newFilters.join(' ');
    viewer.viewport.setFlip(flip);
    viewer.viewport.setRotation(rotation);
  }

  /**
   * @description To reset applied filters
   */
  handleReset() {
    const defaultFilterConfig = {
      rotation: 0,
      flip: false,
      brightness: 100,
      contrast: 100,
      saturate: 100,
      grayscale: 0,
      invert: 0,
    };
    this.setState({ filterConfigs: defaultFilterConfig });
  }

  /**
   * @description render method for image tools
   */
  render() {
    const { enabled, containerId, width, open, windowId } = this.props;
    const {
      filterConfigs: {
        flip = false,
        brightness = 100,
        contrast = 100,
        saturate = 100,
        grayscale = 0,
        invert = 0,
      } 
    } = this.state;

    const isSmallDisplay = ['xs', 'sm'].indexOf(width) >= 0;
    const toggleButton = (
      <div className={(isSmallDisplay && open) ? 'image-tools-container-main' : ''}>
        <Button
          className="MuiButtonBase-root MuiIconButton-root"
          title={open ? 'Collapse image tools' : 'Expand image tools'}
          containerId={containerId}
          clickHandler={this.toggleState}
        >
          <img className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" src={open ? closeImageToolsIcon : tuneImageToolsIcon} />
        </Button>
      </div>
    );
    if (enabled) {
      return (
        <div className={(isSmallDisplay && open) ? '' : 'MuiPaper-elevation4 image-tools-container-main'}>
          {isSmallDisplay && toggleButton}
          {open
          && (
          <React.Fragment>
            {/* image tools here */} 
            <div className={(isSmallDisplay && open) ? '' : 'image-tools-container'}>
              <ImageRotation
                containerId={containerId}
                label="Rotate right"
                clickHandler={() => this.toggleRotate(90)}
                variant="right"  
              />
              <ImageRotation
                containerId={containerId}
                label="Rotate left"
                clickHandler={() => this.toggleRotate(-90)}
                variant="left"
              />
              <ImageFlip
                label="Flip"
                clickHandler={this.toggleFlip}
                flipped={flip}
                containerId={containerId}
              />
            </div>
            <div className={(isSmallDisplay && open) ? '' : 'image-tools-container'}>
              <ImageTool
                type="brightness"
                label="Brightness"
                max={CONSTANTS.BRIGHTNESS.MAX}
                windowId={windowId}
                value={brightness}
                containerId={containerId}
                onChange={this.handleChange('brightness')}
              >
                <img className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" src={brightnessIcon} />
              </ImageTool>
              <ImageTool
                type="contrast"
                label="Contrast"
                max={200}
                windowId={windowId}
                value={contrast}
                containerId={containerId}
                onChange={this.handleChange('contrast')}
              >
                <img className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" src={contrastIcon} />
              </ImageTool>
              <ImageTool
                type="saturate"
                label="Saturation"
                max={200}
                windowId={windowId}
                value={saturate}
                containerId={containerId}
                onChange={this.handleChange('saturate')}
              >
                <img className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" src={saturationIcon} />
              </ImageTool>
              <ImageTool
                type="grayscale"
                variant="toggle"
                label="Greyscale"
                windowId={windowId}
                value={grayscale}
                containerId={containerId}
                onChange={this.handleChange('grayscale')}
              >
                <img className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" src={grayScaleIcon} />
              </ImageTool>
              <ImageTool
                type="invert"
                variant="toggle"
                label="Invert colors"
                windowId={windowId}
                value={invert}
                containerId={containerId}
                onChange={this.handleChange('invert')}
              >
                <img className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" src={invertImageIcon} />
              </ImageTool>
            </div>
            <div className={(isSmallDisplay && open) ? '' : 'image-tools-container'}>
              <Button 
              title="Revert image"
              containerId={containerId}
              clickHandler={this.handleReset}
              className="MuiButtonBase-root MuiIconButton-root"
              >
                <img className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" src={revertImageIcon} />
              </Button>
            </div>
          </React.Fragment>
          )}
          {!isSmallDisplay && toggleButton}
        </div>
      )
    } else {
      return (
        <div></div>
      )
    }
  }
}

ImageToolsPlugin.propTypes = {
  containerId: PropTypes.string.isRequired,
  enabled: PropTypes.bool,
  open: PropTypes.bool,
  updateViewport: PropTypes.func.isRequired,
  updateWindow: PropTypes.func.isRequired,
  viewer: PropTypes.object,
  windowId: PropTypes.string.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

ImageToolsPlugin.defaultProps = {
  enabled: true,
  open: true,
  viewer: undefined,
};

export default {
  target: 'OpenSeadragonViewer',
  mode: 'add',
  name: 'ImageToolsPlugin',
  component: ImageToolsPlugin,
  mapDispatchToProps,
  mapStateToProps,
};
